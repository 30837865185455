<ngx-spinner style="height: 100%;width: 100%;"
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="  margin-left: 2em;">
<mat-form-field  >
  <mat-label>Filtrowanie</mat-label>
  <input matInput (keyup)="filtrujListeFaktur($event)"  placeholder="Po czym chcesz filtrować ? " #input>
  <mat-hint>Filtr działa na wszystkie wartości</mat-hint>
</mat-form-field>
</div>

<table  mat-table [dataSource]="dataSourcefaktura" class="listaFaktur mat-elevation-z8" multiTemplateDataRows style="width: 100%;" matSortActive="dataWystawienia" matSortDirection="desc" matSort>

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="nrFaktury" style="width: 10%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nr faktury </th>
    <td mat-cell *matCellDef="let element"> {{element.nrFaktury}} </td>
  </ng-container>

    <ng-container matColumnDef="dataWystawienia" style="width: 20%;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Wystawienia </th>
      <td mat-cell *matCellDef="let element"> {{element.dataWystawienia}} </td>
    </ng-container>

    <ng-container matColumnDef="nazwa" style="width: 25%;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nabywca </th>
      <td mat-cell *matCellDef="let element"> {{element.nazwa}} </td>
    </ng-container>

    <ng-container matColumnDef="nip" style="width: 25%;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIP </th>
      <td mat-cell *matCellDef="let element"> {{element.nip}} </td>
    </ng-container>

    <ng-container matColumnDef="wartoscNetto" style="justify-content: center;;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kwota Netto </th>
      <td mat-cell *matCellDef="let element"  style="text-align: right;"> {{element.wartoscNetto | mask:"separator.2"}} </td>
    </ng-container>

    <ng-container matColumnDef="wartoscBrutto" style="text-align: center">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kwota Brutto </th>
      <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.wartoscBrutto | mask:"separator.2"}} </td>
    </ng-container>


  <tr mat-header-row *matHeaderRowDef="fakturaColumny"></tr>
  <tr mat-row *matRowDef="let row; columns: fakturaColumny;" (click)="otworzFature(row.idFaktury)"></tr>

</table>
<mat-paginator  class="pagFaktury" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

