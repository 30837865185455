import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-dane-uzytkownika-form',
  templateUrl: './dane-uzytkownika-form.component.html',
  styleUrls: ['./dane-uzytkownika-form.component.css']
})
export class DaneUzytkownikaFormComponent implements OnInit {

  daneUzytkowniaForm:FormGroup;

  constructor(private dialogRef: MatDialogRef<DaneUzytkownikaFormComponent>,
    private http: HttpClient,
    private _snackBar: MatSnackBar,)
    { }

  ngOnInit(): void {

    this.daneUzytkowniaForm = new FormGroup(
      {
        nazwaFirmy: new FormControl(localStorage.getItem("nazwa"),[Validators.required]),
        nip: new FormControl(localStorage.getItem("nip"),[Validators.required]),
        miasto: new FormControl(localStorage.getItem("miasto"),[Validators.required]),
        ulica: new FormControl(localStorage.getItem("ulica"),Validators.required),
        nrMieszkania: new FormControl(localStorage.getItem("nrMieszkania")),
        kodPocztowy: new FormControl(localStorage.getItem("kodPocztowy"),Validators.required),
        nrKontaBankowego: new FormControl(localStorage.getItem("nrKontaBankowego"),Validators.required)

      }

    );
  }

  pobierzDaneUzytkownika()
  {

  }

  public hasError = (controlName: string, errorName: string) =>{

    return this.daneUzytkowniaForm.controls[controlName].hasError(errorName);
  }


  onSubmit()
  {

  }

  zapisz()
  {
    let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajNabywce.php`;

    this.http
        .post(phpUrl, {
          id_uzytkownika: localStorage.getItem("idUzytkownika"),
          nip: this.daneUzytkowniaForm.value.nip,
          nazwa: this.daneUzytkowniaForm.value.nazwaFirmy,
          miasto: this.daneUzytkowniaForm.value.miasto,
          ulica: this.daneUzytkowniaForm.value.ulica,
          nrMieszkania: this.daneUzytkowniaForm.value.nrMieszkania,
          kodPocztowy: this.daneUzytkowniaForm.value.kodPocztowy,
          id_Nabywcy: this.daneUzytkowniaForm
        })

        .subscribe((res: Response) => {
          console.error(res)

          let trescKomunikatu="Nabywca zostal dodany.";


          this._snackBar.open(trescKomunikatu, "", {
            duration: 2000,
          });
          this.dialogRef.close(true);
        },
        error => console.error(error)
      );
  }

  zamknj()
  {
    this.dialogRef.close();
  }
}
