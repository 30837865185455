import { AlarmRodzajuOplaty_class } from './modele/AlarmRodzajuOplaty_class';
import { Oplaty_class } from './modele/Oplaty_class';
import { RodzajOplaty_class } from './modele/RodzajOplaty_class';
import { Uzytkownik_class } from './modele/Uzytkownik-class';
import { UslugaFaktury_class } from './modele/UslugaFaktury_class';
import { Faktura_class } from './modele/Faktura_class';
import { Nabywca_class } from './modele/Nabywca_class';
import { UmowaNabywcy } from './modele/UmowaNabywcy';
import { Injectable } from '@angular/core';
import { Observable, from } from "rxjs";
import { HttpClient, HttpHeaders   } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

public static PHP_API_SERVER = "https://gawsoft.com.pl/fakturken-test/php/";

  constructor(private httpClient: HttpClient) { }

  //logowanie

  logowanie(login,haslo): Observable<Uzytkownik_class[]> {
    return this.httpClient.get<Uzytkownik_class[]>(
      `${ApiServiceService.PHP_API_SERVER}logowanie.php?login=`+login+`&haslo=` + haslo
    );
  }

  //umowy

  odczytajUmowyNabywcy(idNabywcy): Observable<UmowaNabywcy[]> {
    return this.httpClient.get<UmowaNabywcy[]>(
      `${ApiServiceService.PHP_API_SERVER}listaUmowNabywcy.php?idUmowy=` + idNabywcy+
                                                              `&token=` + localStorage.getItem("token")
    );
  }

  daneUmowyPoId(idUmowy): Observable<UmowaNabywcy[]> {
    return this.httpClient.get<UmowaNabywcy[]>(
      `${ApiServiceService.PHP_API_SERVER}daneUmowyPoId.php?idUmowy=` + idUmowy +
                                                          `&token=` + localStorage.getItem("token")
    );
  }


  // faktura

  odczytajListeFakturUzytkownika(): Observable<Faktura_class[]> {
    let myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'application/json');
    return this.httpClient.get<Faktura_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaFakturUzytkownika.php?token=` + localStorage.getItem("token")
      , { headers: myHeaders});
  }

  daneFakturyPoId(idFaktury): Observable<Faktura_class[]> {
    return this.httpClient.get<Faktura_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneFakturyPoId.php?idFaktury=` + idFaktury +
                                                                  `&token=` + localStorage.getItem("token")
    );
  }

  //uslugi faktury

  odczytajListeUslugFaktury(idFaktury): Observable<UslugaFaktury_class[]> {
    return this.httpClient.get<UslugaFaktury_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaUslugFaktury.php?idFaktury=` + idFaktury+
      `&token=` + localStorage.getItem("token")
    );
  }

  odczytajUslugeFaktury(idUslugi): Observable<UslugaFaktury_class[]> {
    return this.httpClient.get<UslugaFaktury_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneUslugiPoId.php?idUslugi=` + idUslugi +
                                                            `&token=` + localStorage.getItem("token")
    );
  }

  autocompleteNazwUslugFaktury(nazwaSzukana): Observable<String[]> {
    return this.httpClient.get<String[]>(
      `${ApiServiceService.PHP_API_SERVER}autoComplteNazwaUslugi.php?token=` + localStorage.getItem("token")
                                               +`&nazwaUslugi=`+nazwaSzukana
    );
  }


  // nabywcy
  daneNabywcyPoId(idNabywcy): Observable<Nabywca_class[]> {
    return this.httpClient.get<Nabywca_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneNabywcyPoId.php?idNabywcy=` + idNabywcy +
                                                                `&token=` + localStorage.getItem("token")
    );
  }

  odczytajListeNabywcowUzytkownika(): Observable<Nabywca_class[]> {
    return this.httpClient.get<Nabywca_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaNabywcyUzytkownika.php?token=` + localStorage.getItem("token")
    );
  }

  //oplaty
  daneOplatyPoId(idOplaty): Observable<Oplaty_class[]> {
    return this.httpClient.get<Oplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneOplatyPoId.php?idOplaty=` + idOplaty +
                                                              `&token=` + localStorage.getItem("token")
    );
  }

  odczytajListeOplatUzytkownika(): Observable<Oplaty_class[]> {
    return this.httpClient.get<Oplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaOplatUzytkownika.php?token=` + localStorage.getItem("token")
    );
  }
  //rodzaj oplaty
  daneRodzajuOplatyPoId(idRodzajOplaty): Observable<RodzajOplaty_class[]> {
    return this.httpClient.get<RodzajOplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneRodzajuOplatyPoId.php?idRodzajuOplaty=` + idRodzajOplaty +
                                                              `&token=` + localStorage.getItem("token")
    );
  }

  odczytajListeRodzajowOplatUzytkownika(aktywna): Observable<RodzajOplaty_class[]> {
    return this.httpClient.get<RodzajOplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaRodzajowOplatUzytkownika.php?token=` + localStorage.getItem("token")+
                                                                            `&aktywna=`+aktywna
    );
  }

  //alarmy rodzajow oplat
  daneAlarmuRodzajuOplatyPoId(idAlarmu): Observable<AlarmRodzajuOplaty_class[]> {
    return this.httpClient.get<AlarmRodzajuOplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}daneAlarmuRodzajuOplatyPoId.php?idAlarmuRodzajuOplaty=` + idAlarmu +
                                                              `&token=` + localStorage.getItem("token")
    );
  }

  odczytajListeAlarmowRodzajuOplatyUzytkownika(idRodzajOplaty): Observable<AlarmRodzajuOplaty_class[]> {
    return this.httpClient.get<AlarmRodzajuOplaty_class[]>(
      `${ApiServiceService.PHP_API_SERVER}listaAlarmowRodzajuOplaty.php?token=` + localStorage.getItem("token")+
                                                                            `&idRodzajOplaty=`+idRodzajOplaty
    );
  }

}
