import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { RodzajOplaty_class } from 'src/app/modele/RodzajOplaty_class';

@Component({
  selector: 'app-rodzaj-oplaty-form',
  templateUrl: './rodzaj-oplaty-form.component.html',
  styleUrls: ['./rodzaj-oplaty-form.component.css']
})
export class RodzajOplatyFormComponent implements OnInit {

  idRodzajOplaty=-1;
  rodzajOplatyForm;
  pobierzDaneRodzajow=false;

  constructor(private dialogRefAll: MatDialog,
    private ApiService:ApiServiceService,
    private dialogRef: MatDialogRef<RodzajOplatyFormComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.rodzajOplatyForm = new FormGroup(
      {

        rodzajOplaty: new FormControl("",[Validators.required,]),

      }
    );

    if(this.data.idRodzajOplaty!=null)
    {
       if(this.data.idRodzajOplaty!=-1)
       {
          this.idRodzajOplaty= this.data.idRodzajOplaty;
          this.pobierzDaneRodzaju();
       }
    }

  }
  pobierzDaneRodzaju() {

    this.ApiService.daneRodzajuOplatyPoId(this.idRodzajOplaty)
    .subscribe((daneRodzaju:RodzajOplaty_class[])=>
    {
      if(daneRodzaju!=null){
        if(daneRodzaju.length>0)
        {
          this.rodzajOplatyForm.controls["rodzajOplaty"].setValue(daneRodzaju[0].rodzajOplaty);

        }
      }
      else
      {
        this._snackBar.open("Błąd pobierania danych ...", "", {
          duration: 2000,
        });

        this.zamknj();
      }
    }
    )

  }

  public bladWalidacji = (controlName: string, errorName: string) =>{
    return this.rodzajOplatyForm.controls[controlName].hasError(errorName);
  }


  onSubmit()
  {
    console.log("onsubmit");

    if (this.rodzajOplatyForm.invalid) {
        console.log("odrzut");
        return this.rodzajOplatyForm.invalid;
      }

    //  this.sprawdzenieDatyWprzyszlosci();
    //}
    console.log("walidacja ok.");
    this.zapisz();
  }

  zapisz()
  {

    let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajRodzajOplatyUzytkownika.php`;
    if(this.idRodzajOplaty!=-1) phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienRodzajOplatyUzytkownika.php`;

      this.http
          .post(phpUrl, {
              token: localStorage.getItem("token"),
              rodzajOplaty: this.rodzajOplatyForm.value.rodzajOplaty,
              idRodzajOplaty:this.idRodzajOplaty
          })

          .subscribe((res: Response) => {
            console.error(res)
            if(res!=null)
             {
              this.pobierzDaneRodzajow=true;

               let komunikat="Rodzaj został dodany.";
               if(this.idRodzajOplaty!=-1 && Number(res) == 0  && Number(res) != -401)
               {
                    komunikat="Błąd zapisu.";
               }
               if(Number(res) == -401)
               {
                 localStorage.clear();
                 this.dialogRefAll.closeAll();
               }
               else if(

                this.idRodzajOplaty!=-1 && Number(res) != -401) komunikat="Rodzaj został zmieniony.";

                this._snackBar.open(komunikat, "", {
                duration: 2000,
              });

              if( Number(res)!=0) this.zamknj();
            }
          },
          error => console.error(error)
        );

  }

  zamknj()
  {
    this.dialogRef.close(this.pobierzDaneRodzajow);

  }

}
