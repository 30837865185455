import { from } from 'rxjs';
//import { from } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
//import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import{MatSelectModule} from '@angular/material/select'
import {MatIconModule} from '@angular/material/icon'
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { MAT_DATE_LOCALE } from '@angular/material/core';
//import {MatDialogRef} from  '@angular/material/dialog/dialog-ref';
import {MatExpansionModule} from '@angular/material/expansion'
import {MatCardModule} from '@angular/material/card';
//import {MatSort} from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FakturaFormComponent } from './form/faktura-form/faktura-form.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule } from '@angular/forms';
import { NabywcaFormComponent } from './form/nabywca-form/nabywca-form.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { UmowaNabywcyFormComponent } from './form/umowa-nabywcy-form/umowa-nabywcy-form.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PozycjaFakturaFormComponent } from './form/pozycja-faktura-form/pozycja-faktura-form.component';
import { ListaFakturUzytkownikaComponent } from './listy/lista-faktur-uzytkownika/lista-faktur-uzytkownika.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { ListaNabywcowUzytkownikaComponent } from './listy/lista-nabywcow-uzytkownika/lista-nabywcow-uzytkownika.component';
import { LogowanieFormComponent } from './form/logowanie-form/logowanie-form.component';
import { DaneUzytkownikaComponent } from './komponenty/dane-uzytkownika/dane-uzytkownika.component';
import { DaneUzytkownikaFormComponent } from './form/dane-uzytkownika-form/dane-uzytkownika-form.component';
import { OplatyFormComponent } from './form/oplaty-form/oplaty-form.component';
import { ListaOplatComponent } from './listy/lista-oplat/lista-oplat.component';

import { getPoslkiPaginatorIntl } from './polski-paginator-intl';
import { RodzajOplatyFormComponent } from './form/rodzaj-oplaty-form/rodzaj-oplaty-form.component';
import { ListaRodzajowOplatUzytkownikaComponent } from './listy/lista-rodzajow-oplat-uzytkownika/lista-rodzajow-oplat-uzytkownika.component';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RodzajOplatyAlarmFormComponent } from './form/rodzaj-oplaty-alarm-form/rodzaj-oplaty-alarm-form.component';
import { ListaAlarmowRodzajuOplatComponent } from './listy/lista-alarmow-rodzaju-oplat/lista-alarmow-rodzaju-oplat.component';

registerLocaleData(localePl);

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    FakturaFormComponent,
    NabywcaFormComponent,
    UmowaNabywcyFormComponent,
    PozycjaFakturaFormComponent,
    ListaFakturUzytkownikaComponent,
    ListaNabywcowUzytkownikaComponent,
    LogowanieFormComponent,
    DaneUzytkownikaComponent,
    DaneUzytkownikaFormComponent,
    OplatyFormComponent,
    ListaOplatComponent,
    RodzajOplatyFormComponent,
    ListaRodzajowOplatUzytkownikaComponent,
    RodzajOplatyAlarmFormComponent,
    ListaAlarmowRodzajuOplatComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,MatExpansionModule,MatIconModule,ReactiveFormsModule,MatDialogModule,MatButtonModule,MatSnackBarModule,MatMenuModule,MatGridListModule,MatCardModule,MatAutocompleteModule,MatPaginatorModule,MatSortModule,MatSlideToggleModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxSpinnerModule,

  ],
  entryComponents: [FakturaFormComponent, NabywcaFormComponent,PozycjaFakturaFormComponent,LogowanieFormComponent,RodzajOplatyFormComponent
  ],
  exports:[MatDatepickerModule,MatNativeDateModule,MatFormFieldModule,MatInputModule,MatTableModule,MatSelectModule,MatExpansionModule,MatIconModule,ReactiveFormsModule,MatDialogModule,MatButtonModule,MatSnackBarModule,MatSlideToggleModule,NgxSpinnerModule],
  providers: [MatDatepickerModule,MatNativeDateModule,MatFormFieldModule, {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},{provide: MatPaginatorIntl, useValue: getPoslkiPaginatorIntl()}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

