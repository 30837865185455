<ngx-spinner style="height: 100%;width: 100%;"
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>


<mat-card class="card-lista-nabywcow" style="margin-left: 2em;margin-top: 1em;margin-bottom: 1em; height: auto; ">
  <mat-card-header>
    <mat-card-title style="width: 100%;">Lista nabywców</mat-card-title>



      <mat-form-field>
        <mat-label>Filtrowanie</mat-label>
           <input matInput (keyup)="filtrujListeNabywcow($event)"  placeholder="Po czym chcesz filtrować ? " #input>
            <mat-hint>Filtr działa na wszystkie wartości</mat-hint>
        </mat-form-field>
      </mat-card-header>
    <mat-mat-card-content>

  <div style="height: auto; ">
  <table mat-table [dataSource]="dataSourceNabywca" class="tabNabywcy mat-elevation-z8" multiTemplateDataRows style="width: 100%;" matSortActive="nazwa" matSortDirection="asc" matSort>

  <ng-container matColumnDef="nazwa" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa </th>
    <td mat-cell *matCellDef="let element"> {{element.nazwa}} </td>
  </ng-container>

  <ng-container matColumnDef="nip" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> NIP </th>
    <td mat-cell *matCellDef="let element"> {{element.nip}} </td>
  </ng-container>

  <ng-container matColumnDef="menu" style="width: 15%;">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="otworzModalWystawianiaFaktury(element.idNabywcy)">
          <mat-icon>note_add</mat-icon>
          <span>Wystaw fakture</span>
        </button>
        <button mat-menu-item (click)="otworzModalDodawaniaUmowy(element.idNabywcy)">
          <mat-icon>note_add</mat-icon>
          <span>Dodaj umowę</span>
        </button>
        <button mat-menu-item (click)="otworzModalNabywcy(element.idNabywcy)">
          <mat-icon>border_color</mat-icon>
          <span>Edytuj</span>
        </button>
    </mat-menu> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="nabywcaKolumn"></tr>
  <tr mat-row *matRowDef="let row; columns: nabywcaKolumn;" ></tr>
  </table>
  <mat-paginator class="pagNabywcy" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</mat-mat-card-content>
</mat-card>
