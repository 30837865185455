<ngx-spinner>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>

<div style="display:flex;">
  <h4 *ngIf="id_umowy == -1" class="modal-title">Nowa Umowa</h4>
  <h4 *ngIf="id_umowy != -1" class="modal-title">Edycja Umowy</h4>

  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>
<form style="width: 50%;margin-left : 25%" [formGroup]="umowaForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <mat-form-field appearance="fill">
      <mat-label>Nr umowy</mat-label>
      <input matInput  maxlength="30" formControlName = "nrUmowy">

      <mat-error *ngIf="bladWalidacji('nrUmowy', 'required')">Nr umowy jest wymagany.</mat-error>
    </mat-form-field>
<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Opis</mat-label>
      <input matInput maxlength="40" formControlName = "opis">

      <!-- <mat-error *ngIf="hasError('dataSprzedazy', 'required')">Nieprawidłowy format daty.</mat-error> -->
    </mat-form-field>
<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Forma płatności</mat-label>
      <mat-select formControlName="formaPlatnosci" >
        <mat-option value=0>Przelew</mat-option>
        <mat-option value=1>Gotówka</mat-option>
      </mat-select>
      <mat-error *ngIf="bladWalidacji('formaPlatnosci', 'required')">Forma płatności musi być wskazana.</mat-error>
    </mat-form-field>

<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Warunek platności</mat-label>
      <div style="display:flex;">
      <div *ngIf="dlWarunku()" >+  </div>
        <input matInput type="number" min="0" max="999" formControlName = "warunekTermPlastnosci" >
    </div>
      <mat-hint>+ dni od daty wystawienia faktury</mat-hint>

      <!-- <mat-error *ngIf="hasError('dataSprzedazy', 'required')">Nieprawidłowy format daty.</mat-error> -->
    </mat-form-field>

<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Nr zamówienia</mat-label>
      <input matInput maxlength="10" formControlName = "nrZamowienia">

      <!-- <mat-error *ngIf="hasError('dataSprzedazy', 'required')">Nieprawidłowy format daty.</mat-error> -->
    </mat-form-field>

<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Kont KG</mat-label>
      <input matInput maxlength="10" formControlName = "kontoKG">

      <!-- <mat-error *ngIf="hasError('dataSprzedazy', 'required')">Nieprawidłowy format daty.</mat-error> -->
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >Zapisz</button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Anuluj</button>

    </div>
</form>

