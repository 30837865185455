import { Nabywca_class } from './../../modele/Nabywca_class';
import { ApiServiceService } from './../../api-service.service';
import { UmowaNabywcy } from './../../modele/UmowaNabywcy';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient, HttpParams } from "@angular/common/http";
import {MatSnackBar} from '@angular/material/snack-bar';
import { UmowaNabywcyFormComponent } from '../umowa-nabywcy-form/umowa-nabywcy-form.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nabywca-form',
  templateUrl: './nabywca-form.component.html',
  styleUrls: ['./nabywca-form.component.css']
})
export class NabywcaFormComponent implements OnInit {

  public nabywcaForm:FormGroup;
  private submitted:boolean;
  public id_nabywcy:number = -1;
  public nabywca:Nabywca_class;

  pobieranieNabywcy:boolean=false;
  pobieranieUmowy:boolean=false;
  odswiezDaneNabywcow:boolean=false;

  ApiServiceService: any;

  constructor(private dialogRefAll: MatDialog,
              private apiService: ApiServiceService,
              private dialogRef: MatDialogRef<NabywcaFormComponent>,
              public dialog: MatDialog,
              private http: HttpClient,
              private _snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  umowaColumny: string[] = [ 'nrUmowy', 'nrZamowienia', 'kontoKG', 'idWarunekPlatnosci'];
  umowyNabywcy :UmowaNabywcy[];
  dataSourceUmowy = new MatTableDataSource<UmowaNabywcy>();

  ngOnInit(): void {

    this.nabywcaForm = new FormGroup(
      {
        nazwaFirmy: new FormControl('',[Validators.required]),
        nip: new FormControl('',[Validators.required]),
        miasto: new FormControl('',[Validators.required]),
        ulica: new FormControl('',Validators.required),
        nrMieszkania: new FormControl(''),
        kodPocztowy: new FormControl('',Validators.required)
      }
    );

    if(this.data.idNabywcy!=null)
    {
      this.id_nabywcy= this.data.idNabywcy;
      if( this.id_nabywcy!=-1)
      {
        this.pobierzDaneNabywcy();
        this.pobierzUmowyNabywcy();
      }
    }


  }
  public hasError = (controlName: string, errorName: string) =>{

    return this.nabywcaForm.controls[controlName].hasError(errorName);
  }

  zamknj()
  {
    this.dialogRef.close(this.odswiezDaneNabywcow);
  }

  onSubmit() {
    console.log("onsubmit");
    this.submitted = true;
   //if (!this.usuwanieWydatku) {
      // stop here if form is invalid
    if (this.nabywcaForm.invalid) {
        console.log("odrzut");
        return this.nabywcaForm.invalid;
      }

    //  this.sprawdzenieDatyWprzyszlosci();
    //}
    console.log("walidacja ok.");
    //this.zapiszNabywce();
    this.weryfikacjaDubla();
  }

  weryfikacjaDubla()
  {
    console.log("zapisz nabywace id"+this.id_nabywcy);
      let phpUrl = `${ApiServiceService.PHP_API_SERVER}sprawdzenieDublaNabywca.php`;

      this.http
          .post(phpUrl, {
            id_uzytkownika: localStorage.getItem("idUzytkownika"),
            nip: this.nabywcaForm.value.nip,
            nazwa: this.nabywcaForm.value.nazwaFirmy
          })

          .subscribe((res: Response) => {
            console.error(res)

            if(Number(res)>0)
            {

            this._snackBar.open("Nabywca z tą nazwą lub nip`em już istnieje.", "", {
              duration: 2000,
            });
            return;

          }

          this.zapiszNabywce();

          },
          error => console.error(error)
        );
  }

    zapiszNabywce()
    {
      console.log("zapisz nabywace id"+this.id_nabywcy);
      let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajNabywce.php`;

      if(this.id_nabywcy!=-1) phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienNabywce.php`;
      this.http
          .post(phpUrl, {
            token: localStorage.getItem("token"),
            nip: this.nabywcaForm.value.nip,
            nazwa: this.nabywcaForm.value.nazwaFirmy,
            miasto: this.nabywcaForm.value.miasto,
            ulica: this.nabywcaForm.value.ulica,
            nrMieszkania: this.nabywcaForm.value.nrMieszkania,
            kodPocztowy: this.nabywcaForm.value.kodPocztowy,
            id_Nabywcy: this.id_nabywcy
          })

          .subscribe((res: Response) => {
            console.error(res)
            let idNabywcy_zapisanego = Number(res);

            if(idNabywcy_zapisanego == -401)
            {
              localStorage.clear();
              this.dialogRefAll.closeAll();
            }
            else{

              let trescKomunikatu="Nabywca zostal dodany.";
              if(this.id_nabywcy!=-1) trescKomunikatu="Zmiany zostały zapisane.";

              this._snackBar.open(trescKomunikatu, "", {
                duration: 2000,
              });
              this.dialogRef.close(true);
            }
          },
          error => console.error(error)
        );
    }

    pobierzDaneNabywcy()
    {
      console.log("pobierz dane nabywcy id"+this.id_nabywcy);

      this.pobieranieNabywcy=true;
      this.statusPobieranychDanych();

      this.apiService.daneNabywcyPoId(this.id_nabywcy)
      .subscribe((daneNabywcy: Nabywca_class[]) => {

        this.nabywca = daneNabywcy[0];

        this.nabywcaForm.controls["nazwaFirmy"].setValue(this.nabywca.nazwa);
        this.nabywcaForm.controls["nip"].setValue(this.nabywca.nip);
        this.nabywcaForm.controls["miasto"].setValue(this.nabywca.miasto);
        this.nabywcaForm.controls["ulica"].setValue(this.nabywca.ulica);
        this.nabywcaForm.controls["nrMieszkania"].setValue(this.nabywca.nrMieszkania);
        this.nabywcaForm.controls["kodPocztowy"].setValue(this.nabywca.kodPocztowy);

        this.pobieranieNabywcy=false;
        this.statusPobieranychDanych();

      },
      err  => {
        this.spinner.hide();
        console.log("blad" + err);
        if(err.status==401)
        {
          localStorage.clear();

          this.dialogRefAll.closeAll();
        }

      });

    }

    pobierzUmowyNabywcy()
    {

      this.pobieranieUmowy=true;
      this.statusPobieranychDanych();


      this.dataSourceUmowy = new MatTableDataSource<UmowaNabywcy>();
      this.apiService.odczytajUmowyNabywcy(this.id_nabywcy)
      .subscribe((umowyNabywcy: UmowaNabywcy[]) => {


        if(umowyNabywcy!=null)
        {
          this.umowyNabywcy = umowyNabywcy;
          this.dataSourceUmowy.data = this.umowyNabywcy;
          //this.dataSourceUmowy.sort = this.sort;
        }
        console.log('umowy'+this.umowyNabywcy);

        this.pobieranieUmowy=false;
        this.statusPobieranychDanych();

      },
      err  => {
        this.spinner.hide();
        console.log("blad" + err);
        if(err.status==401)
        {
          localStorage.clear();
          this.dialogRefAll.closeAll();
        }

      });
    }

    statusPobieranychDanych()
    {
       if(!this.pobieranieNabywcy && !this.pobieranieUmowy)
       {
         this.spinner.hide();
       }
       else
       {
         this.spinner.show();
       }
    }

    otworzModalUmowa(idUmowaPar)
    {
        let odswiezListeUmow:boolean=false;
        console.log("otw modal id umowa"+idUmowaPar);

        let dialogRef = this.dialog.open(UmowaNabywcyFormComponent, {

          width: '400px',
          data: {idNabywcy: this.id_nabywcy,
                  idUmowa : idUmowaPar,
                  odswiezListeUmow: odswiezListeUmow}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          odswiezListeUmow = result;
          if(odswiezListeUmow) { this.pobierzUmowyNabywcy(); }
          console.log('result '+odswiezListeUmow);
          odswiezListeUmow=true;
        });
    }
}
