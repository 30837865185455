<ngx-spinner style="height: 100%;width: 100%;"
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="display:flex;">
  <h4 class="modal-title" >Lista alarmów opłaty - <b>{{rodzajOplatyOpis}}</b></h4>
  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj(false)" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>

<button mat-stroked-button (click)="otworzModalAlarmu(-1)" >Dodaj alarm</button>
<table mat-table [dataSource]="dataSourceAlarmyOplat" class="alarmyOplatLista mat-elevation-z8" multiTemplateDataRows style="width: 100%;" matSortActive="dataOplacenia" matSortDirection="desc" matSort>

  <ng-container matColumnDef="nazwaAlarmu" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa Alarmu</th>
    <td mat-cell *matCellDef="let element" > {{element.nazwaAlarmu}} </td>
  </ng-container>

  <ng-container matColumnDef="alarmDzienMiesiaca" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Uruchamiany dnia <br>miesiąca </th>
    <td mat-cell *matCellDef="let element" > {{element.alarmDzienMiesiaca}} </td>
  </ng-container>

  <ng-container matColumnDef="alarmCoMiesiecy" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cykl co <br>miesięcy </th>
    <td mat-cell *matCellDef="let element" > {{element.alarmCoMiesiecy}} </td>
  </ng-container>

  <ng-container matColumnDef="alarmOdMiesiaca" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Działa od<br> miesiąca </th>
    <td mat-cell *matCellDef="let element" > {{element.alarmOdMiesiaca}} </td>
  </ng-container>

  <ng-container matColumnDef="aktywny" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>  Aktywny </th>
    <td mat-cell *matCellDef="let element"click-stop-propagation> <mat-slide-toggle [checked]="element.aktywny==1" (click)="stopPropag($event)" (change)="zmienAktywny($event, element.idRodzajOplaty) ; false" ></mat-slide-toggle> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="alarmOplatKolumny"></tr>
  <tr mat-row *matRowDef="let row; columns: alarmOplatKolumny;" (click)="otworzModalAlarmu(row.idAlarmRodzajuOplaty)"></tr>
  </table>
  <mat-paginator  class="pagOplaty" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
