import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Oplaty_class } from 'src/app/modele/Oplaty_class';
import { MatTableDataSource } from '@angular/material/table';
import { OplatyFormComponent } from 'src/app/form/oplaty-form/oplaty-form.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-lista-oplat',
  templateUrl: './lista-oplat.component.html',
  styleUrls: ['./lista-oplat.component.css']
})
export class ListaOplatComponent implements OnInit {

  constructor(private dialogRefAll: MatDialog,
        private apiService: ApiServiceService,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService) { }

  oplatyKolumny: string[] = ["dataOplacenia","kwota","rodzajOplaty"];
  oplatyUzytkownika:Oplaty_class[];
  dataSourceOplata = new MatTableDataSource<Oplaty_class>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.pobierzOplaty();

    this.dataSourceOplata.paginator = this.paginator;
    this.dataSourceOplata.sort = this.sort;
  }


  pobierzOplaty()
  {
    this.spinner.show();

    this.apiService.odczytajListeOplatUzytkownika()
    .subscribe((oplaty:Oplaty_class[])=>
    {
      this.oplatyUzytkownika = oplaty;
      this.dataSourceOplata.data = this.oplatyUzytkownika;
      this.spinner.hide();
    },
    err=>{

      this.spinner.hide();
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }

    });


  }

  filtrujListeNabywcow(event:Event)
  {
    const filterVaule = (event.target as HTMLInputElement).value;
    this.dataSourceOplata.filter = filterVaule.trim().toLowerCase();
  }

  otworzOplate(idOplaty)
  {
    console.log(event);
    let dialogRef = this.dialog.open(OplatyFormComponent, {
      data: {idOplaty: idOplaty},
      width: '400px',
      height:'auto'
    });
  }

}
