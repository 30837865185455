<div style="display:flex;">
  <h4 class="modal-title" *ngIf="idRodzajOplaty==-1">Dodawanie rodzaju opłaty</h4>
  <h4 class="modal-title" *ngIf="idRodzajOplaty!=-1">Zmiana rodzaju opłaty</h4>


  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>
<form style="margin-left : 25%; margin-right: 25%;" [formGroup]="rodzajOplatyForm" (ngSubmit)="onSubmit()" autocomplete="off">

    <mat-form-field appearance="fill">
      <mat-label>Rodzaj opłaty</mat-label>
      <input matInput  formControlName = "rodzajOplaty" >
      <mat-error *ngIf="bladWalidacji('rodzajOplaty', 'required')">Rodzaj opłaty jest wymagany.</mat-error>

    </mat-form-field>


    <div mat-dialog-actions>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >Zapisz</button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Anuluj</button>

    </div>
</form>

