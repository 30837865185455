
export class Faktura_class {
	idFaktury: number=-1;;
	nrFaktury: string;
	dataWystawienia: Date;
	dataSprzedazy: Date;
	idFormaPlatnosci: number=-1;
	idNabywcy: number=-1;
	ostatniaZmiana: Date;
	terminPlatnosci: number;
	nrZamowienia: string;
	kontoKG: string;
	idUmowaNabywcy: number;
	nazwa: string;
  nip: string;

  miasto: string;
	ulica: string;
	nrMieszkania: string;
  kodPocztowy: string;

  sprzedajacy_nip: string;
	sprzedajacy_nazwa: string;
	sprzedajacy_miasto: string;
	sprzedajacy_ulica: string;
	sprzedajacy_nrMieszkania: string;
	sprzedajacy_kodPocztowy: string;
	sprzedajacy_nrKontaBankowego: string;


	wartoscNetto: number;
	wartoscBrutto: number;

}
