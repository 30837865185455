<div style="display:flex;">
  <h4 class="modal-title" *ngIf="idAlarmRodzajuOplaty==-1">Dodawanie alarmu</h4>
  <h4 class="modal-title" *ngIf="idAlarmRodzajuOplaty!=-1">Zmiana alarmu</h4>


  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj(false)" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>

<form style="margin-left : 25%; margin-right: 25%;" [formGroup]="alarmForm" (ngSubmit)="onSubmit()" autocomplete="off">

  <mat-form-field appearance="fill">
    <mat-label>Nazwa alarmu</mat-label>
    <input matInput formControlName = "nazwaAlarmu">
    <mat-error *ngIf="bladWalidacji('nazwaAlarmu', 'required')">Nazwa alarmu jest wymagana.</mat-error>

  </mat-form-field>

  <br><br>

  <mat-form-field appearance="fill">
    <mat-label>Alarmuj dnia miesiąca</mat-label>
    <input matInput formControlName = "alarmDzienMiesiaca"  mask="separator.2" [thousandSeparator]="' '" [decimalMarker]="'.'"
    thousandSeparator="."
    decimalMarker=" " >
    <mat-error *ngIf="bladWalidacji('alarmDzienMiesiaca', 'required')">Dzień alarmu jest wymagany.</mat-error>
    <mat-error *ngIf="bladWalidacji('alarmDzienMiesiaca', 'min')"> Dzień alarmu nie może być mniejszy od 1</mat-error>
    <mat-error *ngIf="bladWalidacji('alarmDzienMiesiaca', 'max')"> Dzień alarmu nie może być większy od 31</mat-error>
  </mat-form-field>

  <br><br>

  <mat-form-field appearance="fill">
      <mat-label>Cyklicznie alarmuj co miesięcy:</mat-label>
      <input matInput formControlName = "alarmCoMiesiecy"  mask="separator.2" [thousandSeparator]="' '" [decimalMarker]="'.'"
      thousandSeparator="."
      decimalMarker=" " >
      <mat-hint></mat-hint>
      <mat-error *ngIf="bladWalidacji('alarmCoMiesiecy', 'required')">Cykl miesięcy alarmu jest wymagany.</mat-error>
      <mat-error *ngIf="bladWalidacji('alarmCoMiesiecy', 'min')">Cykl miesięcy alarmu nie może być mniejszy od 1.</mat-error>
  </mat-form-field>

  <br><br>

  <div *ngIf="this.alarmForm.controls['alarmCoMiesiecy'].value>1">
      <mat-form-field appearance="fill" >
        <mat-label>Cykl od miesiąca:</mat-label>
        <mat-select formControlName="alarmOdMiesiaca" (selectionChange)="someMethod($event.value)">

          <mat-option
            *ngFor="let miesiac of miesiace"
            [value]="miesiac.id"
          >
            {{ miesiac.miesiac }}
          </mat-option>
        </mat-select>
        <mat-hint></mat-hint>
        <mat-error *ngIf="bladWalidacji('alarmOdMiesiaca', 'required')">Cykl miesięcy alarmu jest wymagany.</mat-error>

    </mat-form-field>
    <br><br>
</div>


    <mat-form-field style="width: auto;" appearance="fill">
      <mat-label>Wiadomość</mat-label>
      <textarea matInput   maxlength="255" formControlName = "wiadomosc" ></textarea>

    </mat-form-field>

    <div mat-dialog-actions>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >Zapisz</button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj(false)">Anuluj</button>

    </div>
</form>

