
import { Nabywca_class } from './../../modele/Nabywca_class';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { NabywcaFormComponent } from 'src/app/form/nabywca-form/nabywca-form.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UmowaNabywcyFormComponent } from 'src/app/form/umowa-nabywcy-form/umowa-nabywcy-form.component';
import { FakturaFormComponent } from 'src/app/form/faktura-form/faktura-form.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-lista-nabywcow-uzytkownika',
  templateUrl: './lista-nabywcow-uzytkownika.component.html',
  styleUrls: ['./lista-nabywcow-uzytkownika.component.css']
})
export class ListaNabywcowUzytkownikaComponent implements OnInit {

  constructor(private dialogRefAll: MatDialog,
    private apiService: ApiServiceService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService) { }

nabywcaKolumn: string[] =["nazwa","nip","menu" ];
nabywcyUzytkownia: Nabywca_class[];
dataSourceNabywca = new MatTableDataSource<Nabywca_class>();

@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.pobierzNabywcow();
    this.dataSourceNabywca.paginator = this.paginator;
    this.dataSourceNabywca.sort = this.sort;
  }

  filtrujListeNabywcow(event:Event)
  {
    const filterVaule = (event.target as HTMLInputElement).value;
    this.dataSourceNabywca.filter = filterVaule.trim().toLowerCase();
  }

  pobierzNabywcow()
  {
    this.spinner.show();

    this.apiService.odczytajListeNabywcowUzytkownika()
    .subscribe(async (nabywcy: Nabywca_class[]) => {
      this.nabywcyUzytkownia = nabywcy;
      this.dataSourceNabywca.data = this.nabywcyUzytkownia;
      this.spinner.hide();
    },
    err  => {
      this.spinner.hide();
      console.log("blad" + err);
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }
    }

    );
  }

  otworzModalNabywcy(idNabywcy:number)
  {
    let dialogRef = this.dialog.open(NabywcaFormComponent, {
      data:{idNabywcy},
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);

      if(result) {
        this.pobierzNabywcow();
        //this.pobierzUmowyNabywcy();
       }
    });
  }

  otworzModalDodawaniaUmowy(idNabywcy:number)
  {
        let dialogRef = this.dialog.open(UmowaNabywcyFormComponent, {

      width: '400px',
      data: {idNabywcy: idNabywcy ,odswiezListeUmow: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      //odswiezListeUmow = result;

    });
  }

  otworzModalWystawianiaFaktury(idNabywcy: number)
  {
    //this.modalService.open(FakturaFormComponent, { backdrop: 'static' });
    let dialogRef = this.dialog.open(FakturaFormComponent, {
      data:{idNabywcy:idNabywcy,
      width: '600px',
      height:'auto'
    }
  });

  }
}
