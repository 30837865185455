import { HttpHeaders } from '@angular/common/http';
import { Faktura_class } from './../../modele/Faktura_class';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { FakturaFormComponent } from 'src/app/form/faktura-form/faktura-form.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-lista-faktur-uzytkownika',
  templateUrl: './lista-faktur-uzytkownika.component.html',
  styleUrls: ['./lista-faktur-uzytkownika.component.css']
})
export class ListaFakturUzytkownikaComponent implements OnInit {

  constructor(private dialogRefAll: MatDialog,
              private apiService: ApiServiceService,
              public dialog: MatDialog,
              private spinner: NgxSpinnerService) { }

  fakturaColumny: string[] = [ 'nrFaktury', 'dataWystawienia', 'nazwa','nip','wartoscNetto','wartoscBrutto'];
  fakturUzytkownika :Faktura_class[];
  dataSourcefaktura = new MatTableDataSource<Faktura_class>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.pobieranieFakturUzytkonika();
    this.dataSourcefaktura.paginator = this.paginator;
    this.dataSourcefaktura.sort=this.sort;


  }

  pobieranieFakturUzytkonika()
  {
    this.spinner.show();

      this.dataSourcefaktura = new MatTableDataSource<Faktura_class>();
      this.apiService.odczytajListeFakturUzytkownika()
      .subscribe((fakturyUzytkownika:any)=>{

        console.log(fakturyUzytkownika);
        if(fakturyUzytkownika!=null)
        {
          this.fakturUzytkownika=fakturyUzytkownika;
          this.dataSourcefaktura.data=this.fakturUzytkownika;
          this.dataSourcefaktura.paginator = this.paginator;
          this.dataSourcefaktura.sort=this.sort;
          this.spinner.hide();
        }
        console.log('faktury'+this.fakturUzytkownika[0].wartoscNetto);
      },err =>
               {
                 console.log('HTTP Error', err)
                if(err.status==401)
                {
                    this.dialogRefAll.closeAll();
                    localStorage.clear();
                }
      }
      )
  }
  filtrujListeFaktur(event: Event)
  {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue!=null) this.dataSourcefaktura.filter = filterValue.trim().toLowerCase();
  }
  otworzFature(idFaktury)
  {
      console.log("otw fakture "+idFaktury);
      this.otworzModal(idFaktury);
  }

  otworzModal(idFaktura)
  {
    //this.modalService.open(FakturaFormComponent, { backdrop: 'static' });
    let dialogRef = this.dialog.open(FakturaFormComponent, {
      data:{idFaktura: idFaktura},
      width: '600px',
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if(result) { this.pobieranieFakturUzytkonika(); }
      console.log('result modal faktura '+result);
    });
  }

}
