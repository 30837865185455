<ngx-spinner style="height: 100%;width: 100%;"
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>



  <mat-form-field>
  <mat-label>Filtrowanie</mat-label>
      <input matInput (keyup)="filtrujListeNabywcow($event)"  placeholder="Po czym chcesz filtrować ? " #input>
      <mat-hint>Filtr działa na wszystkie wartości</mat-hint>
  </mat-form-field>

  <table mat-table [dataSource]="dataSourceOplata" class="oplatyLista mat-elevation-z8" multiTemplateDataRows style="width: 100%;" matSortActive="dataOplacenia" matSortDirection="desc" matSort>

  <ng-container matColumnDef="rodzajOplaty" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rodzaj </th>
    <td mat-cell *matCellDef="let element" > {{element.rodzajOplaty}} </td>
  </ng-container>

  <ng-container matColumnDef="dataOplacenia" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>  Data opłacenia </th>
    <td mat-cell *matCellDef="let element"> {{element.dataOplacenia}} </td>
  </ng-container>

  <ng-container matColumnDef="kwota" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Kwota </th>
    <td mat-cell *matCellDef="let element"> {{element.kwota}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="oplatyKolumny"></tr>
  <tr mat-row *matRowDef="let row; columns: oplatyKolumny;" (click)="otworzOplate(row.idOplaty)"></tr>
  </table>
  <mat-paginator  class="pagOplaty" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>


