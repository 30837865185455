
export class UmowaNabywcy {
	idUmowyNabywcy: number;
	idNabywcy: number;
	nrUmowy: string;
	opis: string;
	nrZamowienia: string;
  kontoKG: string;
  idFormaPlatnosci: number=1;
	idWarunekPlatnosci: number;
	komentarz: string;
}
