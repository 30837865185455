import { Oplaty_class } from './../../modele/Oplaty_class';
import { RodzajOplaty_class } from './../../modele/RodzajOplaty_class';
import { ApiServiceService } from 'src/app/api-service.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { ListaRodzajowOplatUzytkownikaComponent } from 'src/app/listy/lista-rodzajow-oplat-uzytkownika/lista-rodzajow-oplat-uzytkownika.component';

@Component({
  selector: 'app-oplaty-form',
  templateUrl: './oplaty-form.component.html',
  styleUrls: ['./oplaty-form.component.css']
})
export class OplatyFormComponent implements OnInit {

  oplataForm;
  idOplaty=-1;
  idRodzajOplaty=1;
  rodzajeOplat:RodzajOplaty_class[];

  constructor(private dialogRefAll: MatDialog,
              private ApiService:ApiServiceService,
              private dialogRef: MatDialogRef<OplatyFormComponent>,
              public dialog: MatDialog,
              private http: HttpClient,
              private _snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.oplataForm = new FormGroup(
      {
        dataOplacenia: new FormControl(new Date(),[Validators.required]),
        rodzajOplaty: new FormControl(-1,[Validators.required,]),
        kwota: new FormControl(0,[Validators.required,]),
        komentarz: new FormControl('',)

      }
    );

    if(this.data.idOplaty != null)
    {
      this.idOplaty = this.data.idOplaty;
      if(this.idOplaty != -1)
      {
        this.pobierzDaneOplaty();
      }
    }

    this.pobierzDaneRodzajowOplat();
  }

  pobierzDaneRodzajowOplat()
  {
    this.ApiService.odczytajListeRodzajowOplatUzytkownika(1)
    .subscribe((rodzajeOplat:RodzajOplaty_class[])=>
    {
        this.rodzajeOplat = rodzajeOplat;
    },
    err  => {
      this.spinner.hide();
      console.log("blad" + err);
      if(err.status==401)
      {
        localStorage.clear();
        this.zamknj(false);
      }

    });
  }

  pobierzDaneOplaty()
  {
    this.ApiService.daneOplatyPoId(this.idOplaty)
    .subscribe((oplata: Oplaty_class[]) =>
    {

      this.oplataForm.controls["dataOplacenia"].setValue(oplata[0].dataOplacenia);
      this.oplataForm.controls["rodzajOplaty"].setValue(oplata[0].idRodzajOplaty);
      this.oplataForm.controls["kwota"].setValue(oplata[0].kwota);
      this.oplataForm.controls["komentarz"].setValue(oplata[0].komentarz);

    },
    err  => {
      this.spinner.hide();
      console.log("blad" + err);
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }

    });
  }

  public bladWalidacji = (controlName: string, errorName: string) =>{
    return this.oplataForm.controls[controlName].hasError(errorName);
  }

  onSubmit()
  {
    console.log("onsubmit");

    if (this.oplataForm.invalid) {
        console.log("odrzut");
        return this.oplataForm.invalid;
      }

    //  this.sprawdzenieDatyWprzyszlosci();
    //}
    console.log("walidacja ok.");
    this.zapisz();
    /* if(this.idFaktury==-1)
    {

      this.zapiszFakture();
    }
    else
    {
      this._snackBar.open("zapis zmian faktury w trakcie budowy.", "", {
        duration: 2000,
      });
    } */
  }

  zapisz()
  {

    console.log("rodxzas " +this.oplataForm.rodzajOplaty);

    let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajOplateUzytkownika.php`;
    if(this.idOplaty!=-1) phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienOplate.php`;

      this.http
          .post(phpUrl, {
            token: localStorage.getItem("token"),
            id_oplaty: this.idOplaty,
            dataOplaty: formatDate(
              new Date(this.oplataForm.value.dataOplacenia).toISOString(),
              "yyyy-MM-dd",
              "en-US"
            ),

            rodzajOplaty: this.idRodzajOplaty,
            kwota: this.oplataForm.value.kwota,
            komentarz: this.oplataForm.value.komentarz
          })

          .subscribe((res: Response) => {
            console.error(res)
            if(res!=null && Number(res) != -401)
             {
               let komunikat="Opłata została dodana.";
               if((this.idOplaty!=-1 && Number(res) == 0) || res==null)
               {
                    komunikat="Błąd zapisu.";
               }
               else if(this.idOplaty!=-1) komunikat="Opłata została zmieniona.";

                this._snackBar.open(komunikat, "", {
                duration: 2000,
              });

              if( Number(res)!=0) this.zamknj(true);
            }
            else if(Number(res)== -401)
            {
              localStorage.clear();
              this.dialogRefAll.closeAll();
            }
          },
          error => console.error(error)
        );

  }

  otworzModalListaOplat()
  {
    let dialogRef = this.dialog.open(ListaRodzajowOplatUzytkownikaComponent, {
      data: {idOplaty: -1},
      width: '400px',
      height:'auto'
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result) {

        this.pobierzDaneRodzajowOplat();
       }
    });
}

  zmianaRodzajuOplaty(idRodzaju)
  {
    this.idRodzajOplaty=idRodzaju;
  }
  zamknj(odswiezDane)
  {
    this.dialogRef.close(odswiezDane);
  }

}
