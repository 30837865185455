<ngx-spinner
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="display:flex;">
<h2 *ngIf="id_nabywcy==-1" class="modal-title">Dodawanie nowego nabywcy</h2>
<h2 *ngIf="id_nabywcy!=-1" class="modal-title">Edytowanie nabywcy</h2>

  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>


</div>
<br>
<div class="mat-dialog-content" style="height: auto;">

<form  [formGroup]="nabywcaForm" (ngSubmit)="onSubmit()"  autocomplete="off">

  <mat-form-field style="width: 100%;" appearance="legacy">
    <mat-label>Nazwa</mat-label>
    <input matInput formControlName ="nazwaFirmy" maxlength="40" aria-multiline="true">
    <mat-error *ngIf="hasError('nazwaFirmy', 'required')">Nazwa jest wymagana</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field  appearance="legacy">
    <mat-label>NIP</mat-label>
    <input matInput formControlName="nip" placeholder="XXX-XXX-XX-XX"  mask='000-000-00-00' [validation]="true">
    <mat-error *ngIf="hasError('nip', 'required')">NIP jest wymagany</mat-error>
    <mat-error *ngIf="hasError('nip', 'mask')">Nieprawidłowy format</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: auto; margin-right:5em ;" appearance="legacy">
    <mat-label>Miasto</mat-label>
    <input matInput formControlName="miasto" maxlength="20">
    <mat-error *ngIf="hasError('miasto', 'required')">Miasto jest wymagane.</mat-error>
  </mat-form-field>

  <mat-form-field style="width: auto;" appearance="legacy">
  <mat-label>Ulica</mat-label>
  <input matInput formControlName="ulica" maxlength="20">
  <mat-error *ngIf="hasError('ulica', 'required')">Ulica jest wymagana.</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: auto; margin-right:5em ;" appearance="legacy">
    <mat-label>Nr mieszkania</mat-label>
    <input matInput formControlName="nrMieszkania" maxlength="6">
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Kod pocztowy</mat-label>
    <input matInput formControlName="kodPocztowy" placeholder="XX-XXX"  mask='00-000' [validation]="true">
    <mat-error *ngIf="hasError('kodPocztowy', 'required')">Kod pocztowy jest wymagany</mat-error>
    <mat-error *ngIf="hasError('kodPocztowy', 'mask')">Niepoprawny format.</mat-error>
  </mat-form-field>

  <div mat-dialog-actions>
    <button type="submit" mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button class="btn btn-outline-dark btn-primary" >
      <mat-icon>save</mat-icon>
      Zapisz</button>

    <button mat-stroked-button *ngIf="id_nabywcy!= -1" (click)="otworzModalUmowa(-1)" style="margin-left: auto;margin-right: 2em; display: block;" >
      <mat-icon>library_add</mat-icon>
      Dodaj umowe

    </button>
    <button mat-stroked-button style="margin-left: auto; display: block;" color="warn" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Anuluj
      <mat-icon>cancel</mat-icon>
    </button>

  </div>

</form>

<br>

<div *ngIf="id_nabywcy!= -1 && dataSourceUmowy.data.length>0" >

  <mat-accordion class="example-headers-align">

    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <div  style="display:flex;width: 100%;">
        <mat-panel-title style="margin-top: auto;margin-bottom: auto; margin-left: auto;margin-right: auto; display: block;">
          Lista umów
        </mat-panel-title>

    </div>
  </mat-expansion-panel-header>
<table mat-table [dataSource]="dataSourceUmowy" class="umowy mat-elevation-z8" style="width: 100%;">

    <!-- nrUmowy -->
    <ng-container matColumnDef="nrUmowy" style="justify-content: center;">
      <th mat-header-cell *matHeaderCellDef> Nr umowy </th>
      <td mat-cell *matCellDef="let element"> {{element.nrUmowy}} </td>
    </ng-container>

      <!-- nrZamowienia -->
      <ng-container matColumnDef="nrZamowienia" style="justify-content: center;">
        <th mat-header-cell *matHeaderCellDef> Nr zamówienia </th>
        <td mat-cell *matCellDef="let element"> {{element.nrZamowienia}} </td>
      </ng-container>

      <!-- kontoKG -->
      <ng-container matColumnDef="kontoKG" style="justify-content: center;">
        <th mat-header-cell *matHeaderCellDef> Konto KG </th>
        <td mat-cell *matCellDef="let element"> {{element.kontoKG}} </td>
      </ng-container>



      <!-- idWarunekPlatnosci -->
      <ng-container matColumnDef="idWarunekPlatnosci" style="justify-content: center;">
        <th mat-header-cell *matHeaderCellDef> Termin płatności </th>
        <td mat-cell *matCellDef="let element"> +{{element.idWarunekPlatnosci}} dni</td>
      </ng-container>


    <tr mat-header-row *matHeaderRowDef="umowaColumny"></tr>
    <tr mat-row *matRowDef="let row; columns: umowaColumny;" (click)="otworzModalUmowa(row.idUmowyNabywcy)"></tr>
  </table>



    </mat-expansion-panel>
  </mat-accordion>


    <div mat-dialog-actions>
      <button style="margin-left: auto; display: block;" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Zamknij</button>
    </div>
    </div>
</div>
