import { ListaFakturUzytkownikaComponent } from './listy/lista-faktur-uzytkownika/lista-faktur-uzytkownika.component';
import { ListaOplatComponent } from './listy/lista-oplat/lista-oplat.component';
import { ListaRodzajowOplatUzytkownikaComponent } from './listy/lista-rodzajow-oplat-uzytkownika/lista-rodzajow-oplat-uzytkownika.component';
import { RodzajOplatyFormComponent } from './form/rodzaj-oplaty-form/rodzaj-oplaty-form.component';

import { DaneUzytkownikaFormComponent } from './form/dane-uzytkownika-form/dane-uzytkownika-form.component';

import { PozycjaFakturaFormComponent } from './form/pozycja-faktura-form/pozycja-faktura-form.component';
import { UmowaNabywcyFormComponent } from './form/umowa-nabywcy-form/umowa-nabywcy-form.component';
import { NabywcaFormComponent } from './form/nabywca-form/nabywca-form.component';
import { FakturaFormComponent } from './form/faktura-form/faktura-form.component';
import { Component, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LogowanieFormComponent } from './form/logowanie-form/logowanie-form.component';
import { OplatyFormComponent } from './form/oplaty-form/oplaty-form.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  title = 'Fakturken';
  wersja =' 0.25 testowa';
  @ViewChild(ListaOplatComponent) listaOplat_component:ListaOplatComponent;
  @ViewChild(ListaFakturUzytkownikaComponent) listaFaktur_component:ListaFakturUzytkownikaComponent;

  constructor(public dialog: MatDialog) { }

  getLocalStorageKey(key)
  {
      return localStorage.getItem(key);
  }

  otworzModal()
  {
    //this.modalService.open(FakturaFormComponent, { backdrop: 'static' });
    let dialogRef = this.dialog.open(FakturaFormComponent, {
      data:{},
      width: '600px',
      height:'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if(result) { this.listaFaktur_component.pobieranieFakturUzytkonika(); }
      console.log('result modal faktura '+result);
    });
  }

  otworzModalNabywca()
  {
      let dialogRef = this.dialog.open(NabywcaFormComponent, {
        data:{},
        width: '600px',
      });
  }

  otworzModalUmowa()
  {
      let dialogRef = this.dialog.open(UmowaNabywcyFormComponent, {
        data: {idNabywcy: -1},
        width: '400px',
      });
  }

  otworzModalKonto()
  {
      let dialogRef = this.dialog.open(DaneUzytkownikaFormComponent, {
        data: {idNabywcy: -1},
        width: '400px',
        height:'auto'
      });
  }

  otworzModalOplaty()
  {
      let dialogRef = this.dialog.open(OplatyFormComponent, {
        data: {idOplaty: -1},
        width: '400px',
        height:'auto'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

        if(result) { this.listaOplat_component.pobierzOplaty(); }
        console.log('result modal oplata '+result);
      });

  }

  otworzModalListaOplat()
  {
    let dialogRef = this.dialog.open(ListaRodzajowOplatUzytkownikaComponent, {
      data: {idOplaty: -1},
      width: '400px',
      height:'auto'
    });

}
  otworzModalRodzajOplaty()
  {
      let dialogRef = this.dialog.open(RodzajOplatyFormComponent, {
        data: {idOplaty: -1},
        width: '400px',
        height:'auto'
      });


  }


  otworzModallogowanie()
  {
    let dialogRef = this.dialog.open(LogowanieFormComponent, {
      data: {idFaktury: 1},
      width: '400px',
    });
  }

  wyloguj(){
    localStorage.clear();
  }


}
