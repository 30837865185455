import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/api-service.service';
import { UslugaFaktury_class } from 'src/app/modele/UslugaFaktury_class';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pozycja-faktura-form',
  templateUrl: './pozycja-faktura-form.component.html',
  styleUrls: ['./pozycja-faktura-form.component.css']
})
export class PozycjaFakturaFormComponent implements OnInit {

  public idFaktury:number=-1;
  public idPozycji:number=-1;

  public pozycjaFakturaForm:FormGroup;
  public wartoscPodatku:string="0.00";
  public wartoscNetto:string="0.00";
  public wartoscButto:string="0.00";

  options;

  constructor(private dialogRefAll: MatDialog,
              public dialogRef: MatDialogRef<PozycjaFakturaFormComponent>,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private apiService: ApiServiceService,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {

    if(this.data.idFaktury!=null) this.idFaktury = this.data.idFaktury;


    this.pozycjaFakturaForm = new FormGroup(
      {
        nazwaUslugi: new FormControl('',Validators.required),
        ilosc:new FormControl('0.00', Validators.min(0.01)),
        cenaJednostkowa:new FormControl('0.00', Validators.min(0.01)),
        podatekProc:new FormControl('23',[Validators.min(0),Validators.required]),

      }
    );

    if(this.idFaktury==-1)
    {

              this._snackBar.open("Wystąpił błąd", "", {
                duration: 2000,
              });
              this.zamknj();
    }

    if(this.data.idPozycjiFaktury!=null && this.data.idPozycjiFaktury!=-1)
    {
        this.idPozycji = this.data.idPozycjiFaktury;
        this.pobierzDanePozycji();
    }

    this.pozycjaFakturaForm.controls["nazwaUslugi"].valueChanges.subscribe(term => {
      if (term != '' && term!=null) {
        console.log(term);

        this.apiService.autocompleteNazwUslugFaktury(term).subscribe(
          (data: String[]) => {
            if (data != null) {
              this.options = data;
              console.log(data);
            } else {
              this.options = null;
            }
          },
          error => console.error(error)
        );
      }
    });

  }


  public bladWalidacji = (controlName: string, errorName: string) =>{
    return this.pozycjaFakturaForm.controls[controlName].hasError(errorName);
  }

  pobierzDanePozycji()
  {
    console.log("pobieranie danych pozycji");

    this.apiService.odczytajUslugeFaktury(this.idPozycji)
    .subscribe(async (uslugiFaktury: UslugaFaktury_class[]) => {

      //this.pobieranieUslug=false;
      //this.statusPobieranychDanych();

      this.pozycjaFakturaForm.controls["nazwaUslugi"].setValue(uslugiFaktury[0].nazwaUslugi);

      this.pozycjaFakturaForm.controls["ilosc"].setValue (uslugiFaktury[0].ilosc);

      this.pozycjaFakturaForm.controls["cenaJednostkowa"].setValue(uslugiFaktury[0].cenaJednostkowa);

      this.pozycjaFakturaForm.controls["podatekProc"].setValue(uslugiFaktury[0].podatekProc);

      this.przeliczWartosci();


    },
    err  => {console.log("blad" + err);
                if(err.status==401)
                {
                  localStorage.clear();
                  this.dialogRefAll.closeAll();
                }
            }

    );
  }


  przeliczWartosci()
  {
    let wartoscNetto_number = Math.round(this.pozycjaFakturaForm.value.ilosc *this.pozycjaFakturaForm.value.cenaJednostkowa*100)/100;
    this.wartoscNetto= Number(wartoscNetto_number).toFixed(2);

    let wartoscPodatku_number = Math.round(wartoscNetto_number*this.pozycjaFakturaForm.value.podatekProc)/100;
    this.wartoscPodatku = Number(wartoscPodatku_number).toFixed(2);
    this.wartoscButto = Number(Math.round((wartoscNetto_number+wartoscPodatku_number)*100)/100).toFixed(2);
  }

  onSubmit()
  {
    console.log("onsubmit");
    if(this.pozycjaFakturaForm.invalid)
    {
      return this.pozycjaFakturaForm.invalid;
    }

    this.zapiszPozycjeFaktury();
  }

  zapiszPozycjeFaktury()
    {
      let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajUslugeFaktury.php`;

      if(this.idPozycji!=-1) phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienUslugeFaktury.php`;


      this.http
          .post(phpUrl, {
            idFaktury:this.idFaktury,
            idUslugi:this.idPozycji,
            nazwa: this.pozycjaFakturaForm.value.nazwaUslugi,
            ilosc: this.pozycjaFakturaForm.value.ilosc,
            cenaJedn: this.pozycjaFakturaForm.value.cenaJednostkowa,
            podatek: this.pozycjaFakturaForm.value.podatekProc,
            token: localStorage.getItem("token"),
          })

          .subscribe((res: Response) => {
            console.error(res)
            this.idPozycji = Number(res);
            if(this.idPozycji == -401)
            {
              localStorage.clear();

              this.dialogRefAll.closeAll();
            }
            else{

            this._snackBar.open("Pozycja została dodana do faktury.", "", {
              duration: 2000,
            });
              this.dialogRef.close(true);
           }
          },
          error => console.error(error)
        );
    }

  zamknj()
  {
    this.dialogRef.close(false);
  }

}
