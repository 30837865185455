import { ListaAlarmowRodzajuOplatComponent } from './../lista-alarmow-rodzaju-oplat/lista-alarmow-rodzaju-oplat.component';
import { RodzajOplatyAlarmFormComponent } from '../../form/rodzaj-oplaty-alarm-form/rodzaj-oplaty-alarm-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { RodzajOplaty_class } from './../../modele/RodzajOplaty_class';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { RodzajOplatyFormComponent } from 'src/app/form/rodzaj-oplaty-form/rodzaj-oplaty-form.component';

@Component({
  selector: 'app-lista-rodzajow-oplat-uzytkownika',
  templateUrl: './lista-rodzajow-oplat-uzytkownika.component.html',
  styleUrls: ['./lista-rodzajow-oplat-uzytkownika.component.css']
})
export class ListaRodzajowOplatUzytkownikaComponent implements OnInit {

  constructor(private dialogRefAll: MatDialog,
    private ApiService:ApiServiceService,
    private dialogRef: MatDialogRef<ListaRodzajowOplatUzytkownikaComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService) { }

  rodzajeOplatKolumny: string[] = ["rodzajOplaty","aktywny","edytuj"];
  rodzajeOplatUzytkownika: RodzajOplaty_class[];
  dataSourceRodzajeOplat = new MatTableDataSource<RodzajOplaty_class>();
  odswiezListeRodzajow=false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.pobierzDaneRodzajowOplat();
    this.dataSourceRodzajeOplat.paginator=this.paginator;
    this.dataSourceRodzajeOplat.sort=this.sort;
  }

  pobierzDaneRodzajowOplat()
  {
    this.spinner.show();

    this.ApiService.odczytajListeRodzajowOplatUzytkownika(-1)
    .subscribe((rodzajeOplat:RodzajOplaty_class[])=>
    {
        this.rodzajeOplatUzytkownika = rodzajeOplat;
        this.dataSourceRodzajeOplat.data = this.rodzajeOplatUzytkownika;
        this.spinner.hide();
    }
    ,
    err  => {
      this.spinner.hide();
      console.log("blad" + err);
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }
    }
    );


  }

  zmienAktywny(value, idRodzajOplaty)
  {
    console.log("zmien aktywny value"+value.checked+" id "+idRodzajOplaty)
    let phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienRodzajOplatyAktywna.php`;
    let aktywna =0;
    if (value.checked) aktywna=1;
      this.http
          .post(phpUrl, {
            id_rodzajOplaty: idRodzajOplaty,
               aktywna: aktywna,
              token: localStorage.getItem("token")
          })

          .subscribe((res: Response) => {
            console.error(res)
            if(res!=null)
             {
               if(Number(res) == -401)
               {
                localStorage.clear();
                this.dialogRefAll.closeAll();
               }
               else
               {
                    this.odswiezListeRodzajow=true;
                  let komunikat="Rodzaj opłaty został aktywowany.";
                  if (!value.checked) komunikat="Rodzaj opłaty został deaktywowany.";
                    this._snackBar.open(komunikat, "", {
                    duration: 2000,
                     });
              }
            }
          },
          error => console.error(error)
        );
  }
  otworzModalDodajRodzaj(idRodzaju)
  {
    let dialogRef = this.dialog.open(RodzajOplatyFormComponent, {
      data: {idRodzajOplaty: idRodzaju},
      width: '400px',
      height:'auto'
    });


    dialogRef.afterClosed().subscribe(result => {

      if(result) {
        this.odswiezListeRodzajow=true;
        this.pobierzDaneRodzajowOplat();
       }
    });
  }

  clickStopPropagation(event)
  {
    event.stopPropagation();
  }

  otworzListeAlarmow(event,kliknietyRodzaj,opisRodzaju)
  {
    event.stopPropagation();

    let dialogRef = this.dialog.open(ListaAlarmowRodzajuOplatComponent, {
      data: {idRodzajOplaty: kliknietyRodzaj,
        rodzajOplatyOpis : opisRodzaju},
      width: '600px',
      height:'auto'
    });


    dialogRef.afterClosed().subscribe(result => {

      if(result) {
        this.odswiezListeRodzajow=true;
        this.pobierzDaneRodzajowOplat();
       }
    });
  }

  zamknj()
  {
    this.dialogRef.close(this.pobierzDaneRodzajowOplat);

  }
}
