
<ngx-spinner
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="display:flex;">
  <h2 *ngIf="idFaktury==-1" class="modal-title">Dodawanie faktury</h2>
  <h2 *ngIf="idFaktury!=-1" class="modal-title">Faktura nr {{daneFaktury.nrFaktury}}</h2>

  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
  </button>

</div>


<div class="mat-dialog-content" style="height: auto;">
  <form [formGroup]="fakturaForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div style="display:flex;">
    <div style="width: 40%;" class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Data sprzedaży</mat-label>
        <input matInput [matDatepicker]="picker" formControlName = "dataSprzedazy" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="hasError('dataSprzedazy', 'required')">Nieprawidłowy format daty.</mat-error>
      </mat-form-field>
    </div>


      <div  style="width: 40%;margin-left: 4em;"  class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Data faktury</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="dataFaktury" value="daneFaktury.dataWystawienia" (ngModelChange)="obliczTerminPlatnosci()">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="hasError('dataFaktury', 'required')">Nieprawidłowy format daty.</mat-error>
        </mat-form-field>
      </div>
  </div>


      <div class="fields-container" style="display:flex;">
        <mat-form-field appearance="legacy" style="width: 70%;margin-right: 2em;" >
          <mat-label>Nabywca</mat-label>

           <mat-select formControlName="nabywca"  (ngModelChange)="zmianaNabywcy($event)" (click)="nacisnietyKlawiszWliscieNabywcow('')">
            <mat-option>
                <ngx-mat-select-search
                placeholderLabel="Znajdź nabywce po nazwie lub nip"
                noEntriesFoundLabel="Nie znaleziona nabywcy..."
                (keyup)="nacisnietyKlawiszWliscieNabywcow($event.target.value)">
                <mat-icon ngxMatSelectSearchClear (click)="nacisnietyKlawiszWliscieNabywcow('')">delete</mat-icon>
              </ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let nabywca of nabywcyOdfiltrowani"
              value="{{ nabywca.idNabywcy }}"
            >
              {{ nabywca.nazwa }}
            </mat-option>
          </mat-select>

          <mat-hint></mat-hint>
          <mat-error *ngIf="hasError('nabywca', 'required')">Nabywca jest wymagany</mat-error>
          <mat-error *ngIf="hasError('nabywca', 'maxlength')">Nazwa może mić maksymalnie 20 znaków.</mat-error>
        </mat-form-field>
        <button mat-raised-button style="height: 40px; margin-left: auto; display: block;" type="button" (click)="otworzModalDodawaniaZmianyNabywcy(false)">
          <mat-icon>library_add</mat-icon>
          Dodaj nabywce </button>

      </div>
     <mat-accordion>

      <mat-expansion-panel *ngIf="wybranyNabywca.idNabywcy!=null">
        <mat-expansion-panel-header>
          <mat-panel-title style="margin-right: auto;margin-left: auto;">
           Dane Nabywcy
          </mat-panel-title>

        </mat-expansion-panel-header>

        <b >NIP:</b>
        <br>

        {{wybranyNabywca.nip | mask:'000-000-00-00'}}
        <br><br>

        <b><label for="dateSprzedazy">Adres</label></b>
        <br>

        Ulica {{wybranyNabywca.ulica}} <div *ngIf="wybranyNabywca.nrMieszkania.length>0"> /{{wybranyNabywca.nrMieszkania}}</div>
        <br>
        {{wybranyNabywca.kodPocztowy | mask:'00-000'}} {{wybranyNabywca.miasto}}
        <br>
        <button mat-raised-button style="height: 40px; margin-left: auto; display: block;" type="button" (click)="otworzModalDodawaniaZmianyNabywcy(true)">
          <mat-icon>edit</mat-icon>
          Edytuj dane </button>

      </mat-expansion-panel>
    </mat-accordion>
    <br>
    <br>
    <div class="form-group">

      <div style="display:flex;">
        <div  style="width: 45%;"  class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>Forma płatności</mat-label>
            <mat-select formControlName="formaPlatnosci" [(ngModel)]="formaPlatnosci">
              <mat-option value=0>Przelew</mat-option>
              <mat-option value=1>Gotówka</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('formaPlatnosci', 'required')">Forma płatności musi być wskazana.</mat-error>
          </mat-form-field>
        </div>


      <div  style="width: 45%;margin-left: 1em;"  class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Termin płatności</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="terminPlatnosci" [(ngModel)]="terminPlatnosci">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2  ></mat-datepicker>
          <mat-error *ngIf="hasError('terminPlatnosci', 'required')">Nieprawidłowy format daty.</mat-error>
        </mat-form-field>
      </div>
      </div>
      <div style="display:flex;">
      <div  style="width: 45%;"  class="form-group">
      <mat-form-field appearance="legacy">
        <mat-label>Nr umowy</mat-label>
        <mat-select formControlName="umowa"  (ngModelChange)="zmianaUmowy($event)" >
          <mat-option
            *ngFor="let umowa of umowyNabywcy"
            value="{{ umowa.idUmowyNabywcy }}"
          >
            {{ umowa.nrUmowy }}
          </mat-option>
        </mat-select>
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div  style="width: 45%;"  class="form-group">
      <button *ngIf="wybranyNabywca.idNabywcy!=null" mat-raised-button style="height: 40px;margin-left: 1em; margin-right: auto; display: block;" type="button" (click)="otworzModalUmowa(false)">
        <mat-icon>library_add</mat-icon>
        Dodaj umowe </button>
    </div>
  </div>
  <div style="display:flex;">


    <div  style="width: 45%;"  class="form-group">
      <mat-form-field appearance="legacy">
        <mat-label>Konto KG</mat-label>
        <input matInput formControlName="kontoKG" [(ngModel)]="wybranaUmowa.kontoKG"/>
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div  style="width: 45%;margin-left: 1em;"  class="form-group">
      <mat-form-field appearance="legacy">
        <mat-label>Nr zamówienia</mat-label>
        <input matInput formControlName="nrZamowienia" [(ngModel)]="wybranaUmowa.nrZamowienia"/>
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
  </div>

    </div>

    <div  *ngIf="uslugiFaktury!=null" style="margin-left: auto;margin-right: auto; width: auto;text-align: center;">
     <h3> <b>Do zapłaty: {{sumaWartoscBrutto() | mask: "separator.2"}} PLN</b> </h3>
    </div>

    <div mat-dialog-actions style="margin-bottom: 10px;">
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >
        <mat-icon>save</mat-icon>
        {{opisPrzyciskuZapisz}}</button>

        <button *ngIf="idFaktury!=-1" mat-stroked-button  (click)="modalDodajPozycje(-1)" >
          <mat-icon>library_add</mat-icon>
          Dodaj poz. faktury

        </button>
      <button *ngIf="uslugiFaktury!=null" mat-stroked-button style="margin-left: auto; display: block;"  mat-button type="button" class="btn btn-outline-dark" (click)="generatePdf()" >
        <mat-icon>picture_as_pdf</mat-icon></button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">
        <mat-icon>cancel</mat-icon>
        Anuluj</button>

    </div>

  </form>

  <mat-accordion >
  <mat-expansion-panel *ngIf="wyswietlajUslugi()" style="margin-bottom: 1em;">
    <mat-expansion-panel-header>
      <mat-panel-title style="margin-right: auto;margin-left: auto;">
        <label for="dateSprzedazy">Lista fakturowanych usług i towarów</label>
      </mat-panel-title>

    </mat-expansion-panel-header>

    <div class="table-container">
        <table mat-table [dataSource]="dataSourceUslugi" class="uslugi">

        <ng-container matColumnDef="nazwaUslugi" sticky>
          <th mat-header-cell *matHeaderCellDef> Nazwa </th>
          <td mat-cell *matCellDef="let element"> {{element.nazwaUslugi}} </td>
        </ng-container>

        <ng-container matColumnDef="ilosc">
          <th mat-header-cell *matHeaderCellDef> Ilość </th>
          <td mat-cell *matCellDef="let element"> {{element.ilosc}} </td>
        </ng-container>

        <ng-container matColumnDef="cenaJednostkowa">
          <th mat-header-cell *matHeaderCellDef> Cena jednostkowa </th>
          <td mat-cell *matCellDef="let element"> {{element.cenaJednostkowa | mask: "separator.2"}} </td>
        </ng-container>

        <ng-container matColumnDef="netto">
          <th mat-header-cell *matHeaderCellDef> Wartość Netto </th>
          <td mat-cell *matCellDef="let element"> {{element.wartoscNetto | mask: "separator.2"}} </td>
        </ng-container>

        <ng-container matColumnDef="podatekProc">
          <th mat-header-cell *matHeaderCellDef> VAT </th>
          <td mat-cell *matCellDef="let element"> {{element.podatekProc}} % </td>
        </ng-container>

        <ng-container matColumnDef="wartoscPodatku">
          <th mat-header-cell *matHeaderCellDef> Wartość Podatku </th>
          <td mat-cell *matCellDef="let element"> {{element.wartoscPodatku | mask: "separator.2"}}  </td>
        </ng-container>

        <ng-container matColumnDef="brutto">
          <th mat-header-cell *matHeaderCellDef> Wartość Brutto </th>
          <td mat-cell *matCellDef="let element"> {{element.wartoscBrutto| mask: "separator.2" }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="modalDodajPozycje(row.idUslugiFaktura)"></tr>

      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
</div>


