<div style="display:flex;">
  <h4 class="modal-title" *ngIf="idPozycji==-1">Dodawanie pozycji do faktury</h4>
  <h4 class="modal-title" *ngIf="idPozycji!=-1">Zmiana pozycji faktury</h4>


  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>
<form style="width: 50%;margin-left : 25%" [formGroup]="pozycjaFakturaForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <mat-form-field appearance="fill">
      <mat-label>Nazwa usługi</mat-label>
      <input matInput   maxlength="30" formControlName = "nazwaUslugi" [matAutocomplete]="auto">
            <!-- CREATE AUTO COMPLETE PANEL WITH OPTIONS. -->
            <mat-autocomplete
            #auto="matAutocomplete"

            style="left: inherit !important;
            top: inherit !important;"
          >
            <mat-option *ngFor="let option of options" [value]="option.nazwaUslugi">
              {{ option.nazwaUslugi }}
            </mat-option>
          </mat-autocomplete>
      <mat-error *ngIf="bladWalidacji('nazwaUslugi', 'required')">Nazwa jest wymagany.</mat-error>
    </mat-form-field>
<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Ilość</mat-label>
        <input matInput   formControlName = "ilosc" mask="separator.2" [thousandSeparator]="' '" [decimalMarker]="'.'"
        thousandSeparator="."
        decimalMarker=" " (ngModelChange)="przeliczWartosci()" >
        <mat-error *ngIf="bladWalidacji('ilosc', 'required')">Ilość jest wymagana</mat-error>
        <mat-error *ngIf="bladWalidacji('ilosc', 'min')"> Ilość musi być większa od 0</mat-error>
      <mat-hint></mat-hint>

    </mat-form-field>


<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Cena jednostkowa netto</mat-label>
      <input matInput  formControlName = "cenaJednostkowa"  mask="separator.2" [thousandSeparator]="' '" [decimalMarker]="'.'"
      thousandSeparator="."
      decimalMarker=" " (ngModelChange)="przeliczWartosci()">
      <mat-error *ngIf="bladWalidacji('cenaJednostkowa', 'required')">Cena jednostkowa jest wymagana.</mat-error>
      <mat-error *ngIf="bladWalidacji('cenaJednostkowa', 'min')"> Cena musi być większa od 0</mat-error>

    </mat-form-field>

<br><br>

<mat-form-field appearance="fill">
  <mat-label>Wartość netto</mat-label>
  <input matInput  readonly="true" [(ngModel)]="wartoscNetto" mask="separator.2"  [ngModelOptions]="{standalone: true}">
</mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Podatek %</mat-label>
      <input matInput min="0" max="99" formControlName = "podatekProc">
      <mat-error *ngIf="bladWalidacji('podatekProc', 'required')">Podatek jest wymagany.</mat-error>
      <mat-error *ngIf="bladWalidacji('podatekProc', 'min')"> Podatek nie może być mniejszy od 0</mat-error>

    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Wartość podatku</mat-label>
      <input matInput  readonly="true" [(ngModel)]="wartoscPodatku" mask="separator.2" [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Wartość brutto</mat-label>
      <input matInput  readonly="true" [(ngModel)]="wartoscButto" mask="separator.2" [ngModelOptions]="{standalone: true}">
    </mat-form-field>


    <div mat-dialog-actions>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >Zapisz</button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Anuluj</button>

    </div>
</form>
