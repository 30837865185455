<mat-card class="daneFirmy" style="margin-left: 2em;margin-right: auto; height: auto; max-height: 650px;">
  <mat-card-header>
    <mat-card-title>{{ getLocalStorageKey("nazwa") }}</mat-card-title>
  </mat-card-header>
  <mat-mat-card-content>

    <div style="text-align: center;margin-bottom: 1em;">
    <h3><b>Adres:</b></h3>
  </div>
  <div style="display: flex; ">  Ulica: {{getLocalStorageKey("ulica")}} <p style="white-space: pre;" *ngIf="getLocalStorageKey('nrMieszkania').length>0"> / {{getLocalStorageKey("nrMieszkania")}}</p></div><br>
    {{ getLocalStorageKey("kodPocztowy") | mask:"00-000" }}  {{ getLocalStorageKey("miasto") }}  <br><br>
    NIP : {{ getLocalStorageKey("nip") | mask:"000-000-00-00" }} <br>

    <div style="text-align: center;margin-bottom: 1em;">
      <h3><b>Konto bankowe:</b></h3>
    </div>
    {{ getLocalStorageKey("nrKontaBankowego") | mask:"00 0000 0000 0000 0000 0000 0000" }}
  </mat-mat-card-content>
</mat-card>
