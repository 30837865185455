import { Uzytkownik_class } from './../../modele/Uzytkownik-class';
import { ApiServiceService } from 'src/app/api-service.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logowanie-form',
  templateUrl: './logowanie-form.component.html',
  styleUrls: ['./logowanie-form.component.css']
})
export class LogowanieFormComponent implements OnInit {

  loginForm: FormGroup;
  uzytkownik: Uzytkownik_class;
  bladLogowania:boolean=false;

  constructor(private apiService: ApiServiceService,
              private _snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<LogowanieFormComponent>,) { }

  ngOnInit(): void {
      this.loginForm =  new FormGroup({
      login: new FormControl('',[Validators.required]),
      haslo: new FormControl('',[Validators.required]),
    });
  }

  public zawieraBlad = (sprawdzanyParametr:string,rodzajBledu:string)=>
  {
    return this.loginForm.controls[sprawdzanyParametr].hasError(rodzajBledu);
  }

  onSubmit()
  {
    if(this.loginForm.invalid)
    {
      console.log("blad");

      return this.loginForm.invalid;

    }

    this.zaloguj();
  }

  zaloguj(){

    console.log("login " +this.loginForm.controls["login"].value);

    this.apiService.logowanie(this.loginForm.controls["login"].value,
                              this.loginForm.controls["haslo"].value)
    .subscribe((daneUzytkownika: Uzytkownik_class[]) =>
      {

        localStorage.clear();
        if(daneUzytkownika!=null)
        {
            this.uzytkownik = daneUzytkownika[0];
            console.log(this.uzytkownik.token);
            localStorage.setItem("token", this.uzytkownik.token);
            localStorage.setItem("nazwa", this.uzytkownik.nazwa);
            localStorage.setItem("nip", this.uzytkownik.nip);
            localStorage.setItem("miasto", this.uzytkownik.miasto);
            localStorage.setItem("ulica", this.uzytkownik.ulica);
            localStorage.setItem("nrMieszkania", this.uzytkownik.nrMieszkania);
            localStorage.setItem("kodPocztowy", this.uzytkownik.kodPocztowy);
            localStorage.setItem("nrKontaBankowego", this.uzytkownik.nrKontaBankowego);
            this.bladLogowania=false;
            this._snackBar.open("Witaj "+this.uzytkownik.nazwa,'',{
              duration: 2000,
            });

            this.zamknijModal();
        }
        else
        {
          this._snackBar.open("Błąd logowania...",'',{
            duration: 2000,
            });

            this.bladLogowania=true;
        }
      }
    )

  }

  zamknijModal()
  {
      this.dialogRef.close();
  }

}
