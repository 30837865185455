<div style="display:flex;">
  <h4 class="modal-title" *ngIf="idOplaty==-1">Dodawanie opłaty</h4>
  <h4 class="modal-title" *ngIf="idOplaty!=-1">Zmiana opłaty</h4>


  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj(false)" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>

<form style="margin-left : 25%; margin-right: 25%;" [formGroup]="oplataForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <br><br>
  <mat-form-field appearance="fill">
      <mat-label>Rodzaj opłaty</mat-label>
      <mat-select formControlName="rodzajOplaty" (selectionChange)="zmianaRodzajuOplaty($event.value)">
        <mat-option
          *ngFor="let rodzajOplat of rodzajeOplat"
          [value]="rodzajOplat.idRodzajOplaty"
        >
          {{ rodzajOplat.rodzajOplaty }}
        </mat-option>
      </mat-select>
      <mat-hint></mat-hint>
      <mat-error *ngIf="bladWalidacji('rodzajOplaty', 'required')">Rodzaj opłaty jest wymagany.</mat-error>
      </mat-form-field>
      <button style="margin-left: 60%;margin-right: 10%;" mat-stroked-button type="button" (click)="otworzModalListaOplat()" >Zarządzaj rodz. opłat</button>

<br><br>
    <mat-form-field appearance="fill">
      <mat-label>Kwota opłaty</mat-label>
      <input matInput  formControlName = "kwota"  mask="separator.2" [thousandSeparator]="' '" [decimalMarker]="'.'"
      thousandSeparator="."
      decimalMarker=" " >
      <mat-error *ngIf="bladWalidacji('kwota', 'required')">Kwota opłaty jest wymagana.</mat-error>
      <mat-error *ngIf="bladWalidacji('kwota', 'min')"> Kwota opłaty musi być większa od 0</mat-error>

    </mat-form-field>

<br><br>
    <mat-form-field style="width: fit-content;"  appearance="fill">
      <mat-label>Data Opłacenia</mat-label>
      <input matInput [matDatepicker]="picker" formControlName = "dataOplacenia" >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="bladWalidacji('dataOplacenia', 'required')">Cena jednostkowa jest wymagana.</mat-error>
      <mat-error *ngIf="bladWalidacji('dataOplacenia', 'min')"> Cena musi być większa od 0</mat-error>

    </mat-form-field>

    <mat-form-field style="width: auto;" appearance="fill">
      <mat-label>Komentarz</mat-label>
      <textarea matInput   maxlength="255" formControlName = "komentarz" ></textarea>

    </mat-form-field>

    <div mat-dialog-actions>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="primary" mat-button type="submit" class="btn btn-outline-dark" >Zapisz</button>
      <button mat-stroked-button style="margin-left: auto; display: block;" color="accent" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj(false)">Anuluj</button>

    </div>
</form>

