import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observable } from 'rxjs';
import { Faktura_class } from './../../modele/Faktura_class';
import { UmowaNabywcy } from './../../modele/UmowaNabywcy';

import { ApiServiceService } from './../../api-service.service';
import { Nabywca_class } from './../../modele/Nabywca_class';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
//import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NabywcaFormComponent } from '../nabywca-form/nabywca-form.component';
import { HttpClient, HttpParams } from "@angular/common/http";
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate, formatNumber } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { UslugaFaktury_class } from 'src/app/modele/UslugaFaktury_class';
import { PozycjaFakturaFormComponent } from '../pozycja-faktura-form/pozycja-faktura-form.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { UmowaNabywcyFormComponent } from '../umowa-nabywcy-form/umowa-nabywcy-form.component';
import { Router } from '@angular/router';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-faktura-form',
  templateUrl: './faktura-form.component.html',
  styleUrls: ['./faktura-form.component.css']
})
export class FakturaFormComponent implements OnInit {

  public fakturaForm:FormGroup;
  filtersLoaded: Promise<boolean>;

  pobieranieFaktury: boolean = false;
  pobieranieUmowy: boolean = false;
  pobieranieNabywcy: boolean = false;
  pobieranieZakonczone: boolean = false;
  pobieranieUslug: boolean = false;

  idFaktury:number=-1;
  daneFaktury : Faktura_class = new Faktura_class();

  uslugiFaktury : UslugaFaktury_class[]  ;
  dataSourceUslugi = new MatTableDataSource<UslugaFaktury_class>();
  displayedColumns: string[] = [ 'nazwaUslugi', 'ilosc','cenaJednostkowa','netto','podatekProc','wartoscPodatku','brutto',];

  nabywcy : Nabywca_class[] = null;
  nabywcyOdfiltrowani = this.nabywcy;

  filtorowanyNabywca:String;

  wybranyNabywca:Nabywca_class = new Nabywca_class();
  umowyNabywcy :UmowaNabywcy[];
  wybranaUmowa :UmowaNabywcy = new UmowaNabywcy();

  idnabywcy : Number = -1;
  public nrZamowienia:String="";
  public formaPlatnosci:Number=-1;
  public terminPlatnosci: Date = new Date();
  kontoKG:String="";
  odswiezDaneParent= false;
  opisPrzyciskuZapisz = "Dodaj";


  constructor(private dialogRefAll: MatDialog,
    private apiService: ApiServiceService,
    private dialogRef: MatDialogRef<FakturaFormComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private router: Router) { }


  dataSource = ELEMENT_DATA;


  ngOnInit(): void {
    if(this.data.idFaktura!=null)  this.idFaktury= this.data.idFaktura;
    if(this.data.idNabywcy!=null) this.idnabywcy = this.data.idNabywcy;

    console.log("otrzymane idFaktury "+this.idFaktury);

    this.fakturaForm = new FormGroup(
      {
        dataFaktury: new FormControl(new Date(),[Validators.required]),
        dataSprzedazy: new FormControl(new Date(),Validators.required),
        umowa: new FormControl('',[Validators.required,Validators.maxLength(10)]),
        nrZamowienia: new FormControl('',[Validators.maxLength(10)]),
        kontoKG:new FormControl('',[Validators.maxLength(10)]),
        nabywca: new FormControl('',[Validators.required,Validators.maxLength(10)]),
        terminPlatnosci: new FormControl(this.terminPlatnosci.getDate()+3,Validators.required),
        formaPlatnosci : new FormControl(this.formaPlatnosci,[Validators.required]),
      }
    );

    this.pobierzNabywcow();

    if(this.idFaktury!=-1) {
      this.pobierzDaneFaktury(this.idFaktury);
      this.opisPrzyciskuZapisz = "Zapisz";
    }
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.fakturaForm.controls[controlName].hasError(errorName);
  }

  statusPobieranychDanych()
  {
     if(!this.pobieranieFaktury && !this.pobieranieNabywcy && !this.pobieranieUmowy && !this.pobieranieUslug)
     {
       this.pobieranieZakonczone=true;
       this.spinner.hide();
     }
     else
     {
      this.pobieranieZakonczone=false;
       this.spinner.show();
     }
  }

  onSubmit() {
    console.log("onsubmit");
    //this.submitted = true;
   //if (!this.usuwanieWydatku) {
      // stop here if form is invalid
    if (this.fakturaForm.invalid) {
        console.log("odrzut");
        return this.fakturaForm.invalid;
      }

    //  this.sprawdzenieDatyWprzyszlosci();
    //}
    console.log("walidacja ok.");
    if(this.idFaktury==-1)
    {

      this.zapiszFakture();
    }
    else
    {
      this._snackBar.open("zapis zmian faktury w trakcie budowy.", "", {
        duration: 2000,
      });
    }


  }

  zapiszFakture()
    {
      console.log("zapisz nabywace");
      let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajFakture.php`;

      this.http
          .post(phpUrl, {
            token: localStorage.getItem("token"),
            nrFaktury:'01',
            dataFaktury: formatDate(
              new Date(this.fakturaForm.value.dataFaktury).toISOString(),
              "yyyy-MM-dd",
              "en-US"
            ),
            dataSprzedazy: formatDate(
              new Date(this.fakturaForm.value.dataSprzedazy).toISOString(),
              "yyyy-MM-dd",
              "en-US"
            ),
            idFormaPlatnosci: this.fakturaForm.value.formaPlatnosci,
            terminPlatnosci: formatDate(
              new Date(this.fakturaForm.value.terminPlatnosci).toISOString(),
              "yyyy-MM-dd",
              "en-US"
            ),
            idNabywcy: this.wybranyNabywca.idNabywcy,
            umowa: this.fakturaForm.value.umowa,
            nrZamowienia: this.fakturaForm.value.nrZamowienia,
            kontoKG: this.fakturaForm.value.kontoKG,
            idUmowyNabywcy:this.wybranaUmowa.idUmowyNabywcy
          })

          .subscribe((res: Response) => {
            console.error(res)
            this.idFaktury = Number(res);
            if(this.idFaktury == -401)
            {
              localStorage.clear();
              this.dialogRefAll.closeAll();
            }
            else{
              this.pobierzDaneFaktury(this.idFaktury);
              this.odswiezDaneParent = true;
              this._snackBar.open("Faktura dodana, dodaj do niej pozycje...", "", {
                duration: 3000,
              });
            }

          },
          err  => {
            this.spinner.hide();
            console.log("blad" + err);
            if(err.status==401)
            {
              localStorage.clear();
              this.zamknj();
            }

          });
    }

  zamknj()
  {
    console.log("odswiez dane parent "+ this.odswiezDaneParent);

    this.dialogRef.close(this.odswiezDaneParent);
  }

  pobierzDaneFaktury(idFaktury)
  {
    this.pobieranieFaktury=true;
    this.statusPobieranychDanych();
    console.log("pobrane danych faktury" + idFaktury);

    this.apiService.daneFakturyPoId(idFaktury)
    .subscribe( (faktury: Faktura_class[]) => {
      this.daneFaktury = faktury[0];
      //this.daneFaktury.dataSprzedazy =new Date(faktury[0].dataSprzedazy);

      this.fakturaForm.controls["dataFaktury"].setValue(this.daneFaktury.dataWystawienia);
      this.fakturaForm.controls["dataSprzedazy"].setValue(this.daneFaktury.dataSprzedazy);

      this.fakturaForm.controls["terminPlatnosci"].setValue(this.daneFaktury.terminPlatnosci);
      this.fakturaForm.controls["nabywca"].setValue(this.daneFaktury.idNabywcy);
      this.fakturaForm.controls["formaPlatnosci"].setValue(this.daneFaktury.idFormaPlatnosci);
      this.fakturaForm.controls["umowa"].setValue(this.daneFaktury.idUmowaNabywcy);

      this.fakturaForm.controls["nrZamowienia"].setValue(this.daneFaktury.nrZamowienia);
      this.fakturaForm.controls["kontoKG"].setValue(this.daneFaktury.kontoKG);

      this.wybranaUmowa.nrZamowienia = this.daneFaktury.nrZamowienia;
      this.nrZamowienia=this.daneFaktury.nrZamowienia;
      this.kontoKG=this.daneFaktury.kontoKG;
      this.wybranaUmowa.idUmowyNabywcy=this.daneFaktury.idUmowaNabywcy;
      this.zmianaUmowy(this.daneFaktury.idUmowaNabywcy);
     // console.log("pobrane dane ilosc" + faktury.length);

     this.pobieranieFaktury=false;
    this.statusPobieranychDanych();

    this.pobierzUslugiFaktury();

    },
    err  => {
      this.spinner.hide();
      console.log("blad" + err);
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }

    });
  }

  pobierzUslugiFaktury()
  {
    console.log(     "proba odczytania uslug  id fakt"+this.idFaktury);
    this.pobieranieUslug=true;
    this.statusPobieranychDanych();

    this.apiService.odczytajListeUslugFaktury(this.idFaktury)
    .subscribe(async (uslugiFaktury: UslugaFaktury_class[]) => {
      this.uslugiFaktury = uslugiFaktury;
      this.dataSourceUslugi.data = this.uslugiFaktury;

      this.pobieranieUslug=false;
      this.statusPobieranychDanych();
      console.log("uslugi odczytane id fakt"+this.idFaktury);

    },
    err  => {console.log("blad" + err);
              this.pobieranieUslug=false;
              this.statusPobieranychDanych();

              if(err.status==401)
              {
                localStorage.clear();
                this.dialogRefAll.closeAll();
              }
            }

    );
  }

  modalDodajPozycje(idPozycji)
  {
    let dialogRef = this.dialog.open(PozycjaFakturaFormComponent, {
      data: {idFaktury: this.idFaktury,
              idPozycjiFaktury: idPozycji
            },
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if(result) {
        if(result) this.odswiezDaneParent = true;
        this.pobierzUslugiFaktury();
      }
    });
  }

  pobierzNabywcow()
  {
    this.pobieranieNabywcy=true;
    this.statusPobieranychDanych();

    this.apiService.odczytajListeNabywcowUzytkownika()
    .subscribe(async (nabywcy: Nabywca_class[]) => {
      this.nabywcy = nabywcy;
      this.nabywcyOdfiltrowani=this.nabywcy;
      this.filtersLoaded = Promise.resolve(true);

      if(this.idnabywcy!=-1)
      {
        this.fakturaForm.controls["nabywca"].setValue(this.idnabywcy);
        this.zmianaNabywcy(this.idnabywcy);
      }

      this.pobieranieNabywcy=false;
      this.statusPobieranychDanych();
    },
    err  => {console.log("blad" + err);
              this.pobieranieNabywcy=false;
              this.statusPobieranychDanych();

              if(err.status==401)
              {
                localStorage.clear();
                this.dialogRefAll.closeAll();
              }
            }

    );
  }

  wyswietlajUslugi()
  {

    if(this.uslugiFaktury!=null)
    {

      if(this.uslugiFaktury.length>0)
      {

        return true;

      }
      return false;

    }


    return false;
  }

  zmianaNabywcy($event)
  {
    console.log('zmiana nabywcy id'+ $event);
      this.idnabywcy=Number($event);

    this.nrZamowienia='';
    this.kontoKG='';
    for(let nabywca of this.nabywcy){

        console.log("szukanie nabywca="+nabywca.idNabywcy);
        if(nabywca.idNabywcy==Number($event))
        {
          this.wybranyNabywca=nabywca;
          console.log("wybrany nabywca="+this.wybranyNabywca.nazwa);
          break;
        }
      }
      this.nacisnietyKlawiszWliscieNabywcow("");
      this.pobierzUmowyNabywcy();
  }

  otworzModalDodawaniaZmianyNabywcy(zmiana:boolean)
  {
    let idNabywcyZmiana :Number = -1;
    if(zmiana) { idNabywcyZmiana = this.idnabywcy; }

    let dialogRef = this.dialog.open(NabywcaFormComponent, {
      data:{idNabywcy:idNabywcyZmiana},
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);

      if(result) {
        this.pobierzNabywcow();
        this.pobierzUmowyNabywcy();
       }
    });
  }

  otworzModalUmowa()
  {
      let odswiezListeUmow:boolean=false;

      let dialogRef = this.dialog.open(UmowaNabywcyFormComponent, {

        width: '400px',
        data: {idNabywcy: this.wybranyNabywca.idNabywcy ,odswiezListeUmow: odswiezListeUmow}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        odswiezListeUmow = result;
        if(odswiezListeUmow) { this.pobierzUmowyNabywcy(); }
        console.log('result '+odswiezListeUmow);
      });
  }

  pobierzUmowyNabywcy()
  {
    this.pobieranieUmowy=true;
    this.statusPobieranychDanych();
    console.log("pobieranie umow id nabywcy "+this.idnabywcy);
    this.apiService.odczytajUmowyNabywcy(this.idnabywcy)
    .subscribe(async(umowyNabywcy: UmowaNabywcy[]) => {
      this.umowyNabywcy = umowyNabywcy;
      console.log(this.umowyNabywcy);
      this.filtersLoaded = Promise.resolve(true);
      this.pobieranieUmowy=false;
      if(this.wybranaUmowa.idUmowyNabywcy!=-1) this.zmianaUmowy(this.wybranaUmowa.idUmowyNabywcy);
      this.statusPobieranychDanych();
    },
    err  => {
      console.log("blad" + err);

      this.pobieranieUmowy=false;
      this.statusPobieranychDanych();
      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }

    });

  }

  nacisnietyKlawiszWliscieNabywcow(value) {
    this.nabywcyOdfiltrowani = this.odfiltrujNabywcow(value);

    }

    odfiltrujNabywcow(value: string) {
      let filter = value.toLowerCase();
      let filterNip = value.replace(/-/g, '');
      console.log(filterNip);


      return this.nabywcy.filter(option =>  option.nazwa.toLowerCase().includes(filter) || option.nip.includes(filterNip) );
    }

  obliczTerminPlatnosci()
  {
    this.terminPlatnosci=new Date(this.fakturaForm.value.dataFaktury);
    console.log("data faktury "+this.terminPlatnosci);
    this.terminPlatnosci.setDate(this.terminPlatnosci.getDate()+Number(this.wybranaUmowa.idWarunekPlatnosci));

    console.log(this.wybranaUmowa.idWarunekPlatnosci);
    console.log(this.terminPlatnosci);

  }

  zmianaUmowy($event)
  {
    console.log('zmiana umowy na id'+ $event)

    if( this.umowyNabywcy!=null)//this.idFaktury==-1)
    {
        if(this.umowyNabywcy.length>0)
        {
        for(let umowa of this.umowyNabywcy){
            console.log("szukana umowa "+event);
            if(umowa.idUmowyNabywcy==Number($event) && this.pobieranieZakonczone)
            {

              this.wybranaUmowa = umowa;
              console.log("umowa znaleziona nr umowy "+umowa.nrUmowy);

              this.nrZamowienia=umowa.nrZamowienia;
              this.kontoKG=umowa.kontoKG;
              this.wybranaUmowa.nrUmowy=umowa.nrUmowy;
              this.formaPlatnosci=umowa.idFormaPlatnosci;

              //this.terminPlatnosci.setDate(Date.parse(this.fakturaForm.value.terminPlatnosci));

              this.obliczTerminPlatnosci();

              break;
            }
          }
        }

    }

  }

  formatowanieNIP(Nip:string)
  {

    if(Nip.length ==10)
    {
      return Nip.substring(0,3)+"-"+Nip.substring(3,6)+"-"+Nip.substring(6,8)+"-"+Nip.substring(8,10);
    }


  }

  formatowanieKodPocztowy(kodPocztowy:string)
  {
    if(kodPocztowy.length==5)
    {
      return kodPocztowy.substring(0,2)+"-"+kodPocztowy.substring(2,5)
    }
  }

  formatowanieNrKontaBankowego(nrKonta:string)
  {
    return nrKonta.substring(0,2)+' '+
            nrKonta.substring(2,6)+" "+
            nrKonta.substring(6,10)+" "+
            nrKonta.substring(10,14)+" "+
            nrKonta.substring(14,18)+" "+
            nrKonta.substring(18,22)+" "+
            nrKonta.substring(22,26);
  }

  sposobZaplaty()
  {
    if(this.daneFaktury.idFormaPlatnosci==0)
    {
      return "Przelew";

    }

    return "Gotówka";

  }

  sumaWartoscNetto()
  {
    let wartosc:number=0;

    this.uslugiFaktury.forEach(usluga => {
      wartosc+=Number(usluga.wartoscNetto);
    });

    return wartosc;

  }

  sumaWartoscPodatku()
  {
    let wartosc:number=0;

    this.uslugiFaktury.forEach(usluga => {
      wartosc+=Number(usluga.wartoscPodatku);
    });

    return wartosc;

  }

  sumaWartoscBrutto()
  {
    let wartosc:number=0;

    this.uslugiFaktury.forEach(usluga => {
      wartosc+=Number(usluga.wartoscBrutto);
    });

    return wartosc;

  }


  generatePdf(){

    let nrMieszkaniaSprzedajacy='',nrMieszkaniaNabywca='';

    if(this.daneFaktury.nrMieszkania.length>0) nrMieszkaniaNabywca = " / " + this.daneFaktury.nrMieszkania;
    if(this.daneFaktury.sprzedajacy_nrMieszkania.length>0) nrMieszkaniaSprzedajacy= " / " + this.daneFaktury.sprzedajacy_nrMieszkania;

    const documentDefinition = {

      footer: function (currentPage, pageCount){ return [{
        columns: [
          { text: 'Wydrukowano z programu Fakturken       -       (C) Gawsoft (2020)       -       http://gawsoft.com.pl', alignment: 'center' },
          { text: currentPage.toString() + ' z ' + pageCount+ '  ', alignment: 'right', width:40, margin: [0, 0, 10, 0]}
        ]
      }]
      },
      content: [{
      text: '',
      style: ''
    },
    {
      table: {
        widths: ['65%','20%','15%'],

        body:  [
          [{text:''},{text: 'Data wystawienia: ', style: '', border: [true, true, true, true], },{text: this.daneFaktury.dataWystawienia, style: '', border: [true, true, true, true] }],
          [{text:'', margin: [0, 0, 0, 30]},{text: 'Data sprzedaży: ', style: '', border: [true, true, true, true], },{text: this.daneFaktury.dataSprzedazy, style: '', border: [true, true, true, true] }]
        ]

      },layout: 'noBorders'
    },
    {
      table: {
        widths: '100%',
        body:  [[{
          text: 'FAKTURA VAT nr '+this.daneFaktury.nrFaktury,
          style: 'nrFaktury',
          border: [true, true, true, true],
        }]],

      },
      layout: 'defaultBorder: true'
    },
    {
			table: {
        headerRows: 1,
        widths: [240, '*', 240],
				body: [
					[{text: 'Sprzedający:', style: 'nabywcaSprzedawcaNaglowek',border: [false, false, false, false],}, {text:'', border: [false, false, false, false]}, {text: 'Nabywca:', style: 'nabywcaSprzedawcaNaglowek', border: [false, false, false, false]}],
					[{text: this.daneFaktury.sprzedajacy_nazwa ,style: 'nabywcaSprzedawcaNazwaFirmy', border: [true, true, true, false], margin: [0, 0, 0, 25]},{text:'', border: [false, false, false, false]}, {text:this.wybranyNabywca.nazwa,style: 'nabywcaSprzedawcaNazwaFirmy', border: [true, true, true, false]}],
					[{text:'ul. '+this.daneFaktury.sprzedajacy_ulica+ nrMieszkaniaSprzedajacy,style: 'nabywcaSprzedawcaPozycje',border: [true, false, true, false]},{text:'', border: [false, false, false, false]}, {text:'Ul. '+this.wybranyNabywca.ulica+ nrMieszkaniaNabywca, style: 'nabywcaSprzedawcaPozycje', border: [true, false, true, false]}],
					[{text: this.formatowanieKodPocztowy(this.daneFaktury.sprzedajacy_kodPocztowy)+' '+this.daneFaktury.sprzedajacy_miasto,style: 'nabywcaSprzedawcaPozycje', border: [true, false, true, false]},{text:'', border: [false, false, false, false]} ,{text: this.formatowanieKodPocztowy(this.wybranyNabywca.kodPocztowy)+' '+this.wybranyNabywca.miasto, style: 'nabywcaSprzedawcaPozycje', border: [true, false, true, false]}],
					[{text:'NIP: '+this.formatowanieNIP(this.daneFaktury.sprzedajacy_nip),style: 'nabywcaSprzedawcaPozycje', border: [true, false, true, true]},{text:'', border: [false, false, false, false]} ,{text:'NIP: '+this.formatowanieNIP(this.wybranyNabywca.nip), style: 'nabywcaSprzedawcaPozycje', border: [true, false, true, true]}],
				]
			},
			layout: 'defaultBorder: false'
    },

    {

			table: {
        headerRows: 1,
        widths: [240, '*', 240],

				body: [
					[{text: [ {text:'Sposób zapłaty: ' , bold:true}, this.sposobZaplaty() ], style: '',margin: [0, 10, 0, 0]}, {text:'', }, {text: [{text:'Nr umowy: ',bold:true},this.wybranaUmowa.nrUmowy], style: '', margin: [0, 10, 0, 0]}],
					[{text:[ {text:'Termin zapłaty: ',bold:true} ,this.daneFaktury.terminPlatnosci],style: '', },{text:'', }, {text:[{text:'Nr zamówienia: ',bold:true}, this.daneFaktury.nrZamowienia],style: '', }],
					[{text:[ {text:'Nr konta: ',bold:true, },{text: this.formatowanieNrKontaBankowego(this.daneFaktury.sprzedajacy_nrKontaBankowego),fontSize: 11}],style: '',margin: [0, 0, 0, 30]},{text:'', }, {text:[{text:'Konto KG: ',bold:true},this.daneFaktury.kontoKG], style: '', }],
				]
			},
			layout: 'noBorders'
    },

    this.PDF_ListaFakturowanychPozycji(this.uslugiFaktury),

    {
      table: {
        widths: [20, 125, 30, 60, 60, 25, 60, 60],

        body: [
          [{
            text: '',
            border: [false, false, false, false]

          },
          {
            text: '',
            border: [false, false, false, false]

          },
          {
            text: '',
            border: [false, false, false, false]
          },
          {
            text: 'RAZEM',
            border: [false, false, false, false]
          },
          {
            text: formatNumber(this.sumaWartoscNetto(),'pl-PL','1.2-2') , style: 'pozycjeFaktury', alignment:'right'

          },
          {
            text: '',
            style: ''
          },
          {
            text: formatNumber(this.sumaWartoscPodatku(),'pl-PL','1.2-2') , style: 'pozycjeFaktury', alignment:'right'
          },
          {
            text: formatNumber(this.sumaWartoscBrutto(),'pl-PL','1.2-2') , style: 'pozycjeFaktury', alignment:'right'
          },
          ]]
      }
    },

    {
      unbreakable: true,
      margin:[0,50,0,0],
      table: {
        widths: [20, 125, 30, 60, 60, 25, 60, 60],
        height: 40,
        body: [
          [
          {
            text: '',
            border: [false, false, false, false]
          },

          {
            text: 'Do zapłaty: '+ formatNumber(this.sumaWartoscBrutto(),'pl-PL','1.2-2') +' PLN.',
            colSpan: 3,
            border: [false, false, false, true],

          },

          {
            text: '',
            border: [false, false, false, false]
          },
          {
            text: '',
            border: [false, false, false, false]
          },
          {
            text: '',
            border: [false, false, false, false]
          },
          {
            text: '',
            border: [false, false, false, false],

          },
          ]]
      }
    },

    {
			stack: [
			' '	,
				{text: ' ', },
			],

      margin:[0,70,0,0]
		},

    {
      absolutePosition: {x: 40, y: 520},
			table: {
        headerRows: 1,
        widths: [170, '*', 170],

				body: [
          [{text: '' , style: '',border: [false, false, false, true],margin: [0, 210, 0, 0]}, {text:'', border:[false, false, false, false]}, {text:'',bold:true, style: '',border: [false,  false, false,true]}],
					[{text: 'podpis osoby upoważnionej\ndo odbioru faktury VAT' , style: '',border: [false, false, false, false],}, {text:'', border: [false, false, false, false]}, {text:'podpis osoby upoważnionej\ndo wystawienia faktury VAT', border: [false, false, false, false]}],
				]
			},
			layout: 'defaultBorder: false'
    },
    ],
    styles: {
      nrFaktury:
      {
        fillColor: '#d3d3d3',
        bold: true,
        alignment: 'center'
      },
      nabywcaSprzedawcaNaglowek:
      {
        widths:  120,
        border: [false, false, false, false],
        bold: true,
        fillColor: 'white',
        margin: [0, 20, 0, 3]
      },

      nabywcaSprzedawcaNazwaFirmy:
      {
        border: [false, false, false, false],
        fillColor: '#d3d3d3',
        bold: true,
        margin: [0, 0, 0, 0]
      },

      nabywcaSprzedawcaPozycje:
      {
        border: [false, false, false, false],
        fillColor: '#d3d3d3',
        bold: true,
        margin: [10, 0, 0, 0]
      },

      naglowekPozycjeFaktury:
      {
        border: [false, false, false, false],
        fillColor: '#d3d3d3',
        bold: true,
        alignment: 'center',
        fontSize: 11
      },

      pozycjeFaktury:
      {
        fontSize: 10
      }

    }
  };
    pdfMake.createPdf(documentDefinition).open();

  }



  PDF_ListaFakturowanychPozycji(uslugiFaktury:UslugaFaktury_class[]) {
    let n = 0;
   return {
     table: {
       widths: [20, 125, 30, 60, 60, 25, 60, 60],

       body: [
         [

           {
             text: '\nLp.',
             style: 'naglowekPozycjeFaktury'

           },
           {
           text: '\nNazwa usługi',
           style: 'naglowekPozycjeFaktury'

         },
         {
           text: '\nIlość',
           style: 'naglowekPozycjeFaktury'
         },
         {
           text: 'Cena Jedno.\nnetto\n(PLN)',
           style: 'naglowekPozycjeFaktury'
         },
         {
           text: 'Wartość\nnetto\n(PLN)',
           style: 'naglowekPozycjeFaktury'
         },
         {
           text: '\nPod\n%',
           style: 'naglowekPozycjeFaktury'
         },
         {
           text: 'Kwota\npodatku\n(PLN)',
           style: 'naglowekPozycjeFaktury'
         },
         {
           text: 'Wartość\nbrutto\n(PLN)',
           style: 'naglowekPozycjeFaktury'
         },
         ],

         ...uslugiFaktury.map(usl => {n++;
           return [{text :n, style: 'pozycjeFaktury',alignment : 'center'},
           {text :usl.nazwaUslugi, style: 'pozycjeFaktury',alignment : 'center'},
           {text : formatNumber(usl.ilosc,'pl-PL','1.0-0'), style: 'pozycjeFaktury',alignment : 'right'},
           {text: formatNumber(usl.cenaJednostkowa,'pl-PL','1.2-2'), style: 'pozycjeFaktury',alignment : 'right'},
           {text: formatNumber(usl.wartoscNetto,'pl-PL','1.2-2') ,style: 'pozycjeFaktury', alignment : 'right' },
            { text:usl.podatekProc,style: 'pozycjeFaktury', alignment : 'right'},
             {text:formatNumber(usl.wartoscPodatku,'pl-PL','1.2-2'),style: 'pozycjeFaktury', alignment : 'right' },
             {text:formatNumber(usl.wartoscBrutto,'pl-PL','1.2-2'),style: 'pozycjeFaktury',alignment: 'right' }];
         })
       ]
     }
   };
 }

  dateValidator(c: AbstractControl): { [key: string]: boolean } {
    let value = c.value;
    if (value && typeof value === "string") {
      let match = value.match(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
      if (!match) {
        return { 'dateInvalid': true };
      } else if (match && match[0] !== value) {
        return { 'dateInvalid': true };
      }
    }
    return null;
  }
}



export interface PeriodicElement {
  nazwaUs: string;
  position: number;
  cenaj: number;
  ilosc: number;
  vat:number;
  netto:number;
  brutto:number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, nazwaUs: 'Hydrogen', cenaj: 1.79, ilosc: 3,vat:23,netto:234.23,brutto:123234.64},
  {position: 2, nazwaUs: 'Hydrogen sdsdsd', cenaj: 179.00, ilosc: 3,vat:23,netto:234.12,brutto:234.21},
  {position: 3, nazwaUs: 'Hydrogen', cenaj: 1.79, ilosc: 3,vat:23,netto:234.32,brutto:234.43}

];



