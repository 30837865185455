import { AlarmRodzajuOplaty_class } from './../../modele/AlarmRodzajuOplaty_class';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/api-service.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-rodzaj-oplaty-alarm-form',
  templateUrl: './rodzaj-oplaty-alarm-form.component.html',
  styleUrls: ['./rodzaj-oplaty-alarm-form.component.css']
})
export class RodzajOplatyAlarmFormComponent implements OnInit {

  alarmForm;
  idAlarmRodzajuOplaty=-1;
  idRodzajOplaty=-1;

  miesiace = [
    {id : 1, miesiac :'Styczeń'},
    {id : 2, miesiac :'Luty'},
    {id : 3, miesiac :'Marzec'},
    {id : 4, miesiac :'Kwiecień'},
    {id : 5, miesiac :'Maj'},
    {id : 6, miesiac :'Czerwiec'},
    {id : 7, miesiac :'Lipiec'},
    {id : 8, miesiac :'Sierpień'},
    {id : 9, miesiac :'Wrzesień'},
    {id : 10, miesiac :'Pażdziernik'},
    {id : 11, miesiac :'Listopad'},
    {id : 12, miesiac :'Grudzień'}];

  constructor(private dialogRefAll: MatDialog,
    private ApiService:ApiServiceService,
    private dialogRef: MatDialogRef<RodzajOplatyAlarmFormComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.alarmForm = new FormGroup(
      {
        nazwaAlarmu: new FormControl('',Validators.required),
        alarmDzienMiesiaca: new FormControl('1',[Validators.required, Validators.min(1),Validators.max(31)]),
        alarmOdMiesiaca : new FormControl(1,Validators.required),
        alarmCoMiesiecy: new FormControl('1',[Validators.required,Validators.min(1)]),
        wiadomosc: new FormControl('')
      }
    );

    if(this.data.idAlarm!=null)
    {
      this.idAlarmRodzajuOplaty = this.data.idAlarm;
      if(this.idAlarmRodzajuOplaty!=-1) this.pobierzDaneAlarmu();
    }
    if(this.data.idRodzajOplaty!=null) this.idRodzajOplaty = this.data.idRodzajOplaty;


  }

  public bladWalidacji = (controlName: string, errorName: string) =>{
    return this.alarmForm.controls[controlName].hasError(errorName);
  }

  someMethod(wartosc)
  {
    console.log(wartosc);

  }

  onSubmit()
  {

    if (this.alarmForm.invalid) {

        return this.alarmForm.invalid;
      }

    this.zapisz();
  }

  pobierzDaneAlarmu()
  {
     this.ApiService.daneAlarmuRodzajuOplatyPoId(this.idAlarmRodzajuOplaty)
     .subscribe((daneAlarmu:AlarmRodzajuOplaty_class[])=>
     {
        if(daneAlarmu.length>0)
        {
            this.alarmForm.controls["nazwaAlarmu"].setValue(daneAlarmu[0].nazwaAlarmu);
            this.alarmForm.controls["alarmDzienMiesiaca"].setValue(daneAlarmu[0].alarmDzienMiesiaca);
            this.alarmForm.controls["alarmOdMiesiaca"].setValue(daneAlarmu[0].alarmOdMiesiaca);
            this.alarmForm.controls["alarmCoMiesiecy"].setValue(daneAlarmu[0].alarmCoMiesiecy);
            this.alarmForm.controls["wiadomosc"].setValue(daneAlarmu[0].wiadomosc);
        }
        else
        {
            this._snackBar.open("Błąd pobierania danych ...", "", {
              duration: 2000,
            });

            this.zamknj(false);
        }
     }
     );
  }

  zapisz()
  {
    console.log("id"+this.idAlarmRodzajuOplaty);

    let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajAlarmRodzajuOplaty.php`;
    if(this.idAlarmRodzajuOplaty!=-1) phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienAlarmRodzajuOplaty.php`;

    this.http
          .post(phpUrl, {
              token: localStorage.getItem("token"),
              idAlarmRodzajuOplaty:this.idAlarmRodzajuOplaty,
              idRodzajOplaty:this.idRodzajOplaty,
              nazwaAlarmu: this.alarmForm.value.nazwaAlarmu,
              alarmDzienMiesiaca: this.alarmForm.value.alarmDzienMiesiaca,
              alarmOdMiesiaca: this.alarmForm.value.alarmOdMiesiaca,
              alarmCoMiesiecy: this.alarmForm.value.alarmCoMiesiecy,
              wiadomosc: this.alarmForm.value.wiadomosc

          })

          .subscribe((res: Response) => {
            console.error(res)
            if(res!=null)
             {

               let komunikat="Alarm został dodany.";
               if(this.idRodzajOplaty!=-1 && Number(res) == 0  && Number(res) != -401)
               {
                    komunikat="Błąd zapisu.";
               }
               if(Number(res) == -401)
               {
                 localStorage.clear();
                 this.dialogRefAll.closeAll();
               }
               else if(

                this.idAlarmRodzajuOplaty!=-1 && Number(res) != -401) komunikat="Alarm został zmieniony.";

                this._snackBar.open(komunikat, "", {
                duration: 2000,
              });

              if( Number(res)!=0) this.zamknj(true);
            }
          },
          error => console.error(error)
        );
  }

  zamknj(odswiezDane)
  {
    this.dialogRef.close(odswiezDane);
  }

}
