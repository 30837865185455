<h3>Logowanie</h3>
<ngx-spinner>
  <p style="font-size: 20px; color: white">Logowanie...</p>
</ngx-spinner>

<div class="mat-dialog-content" style="height: auto;">

  <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="onSubmit()">

    <mat-form-field style="width: 100%;" appearance="legacy">
      <mat-label>Login</mat-label>
      <input matInput formControlName ="login" maxlength="10" aria-multiline="true">
      <mat-error *ngIf="zawieraBlad('login','required')">Login jest wymagany</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 100%;" appearance="legacy">
      <mat-label>Hasło</mat-label>
      <input matInput type="password" formControlName ="haslo" maxlength="10" aria-multiline="true">
      <mat-error *ngIf="zawieraBlad('haslo','required')">Hasło jest wymagane</mat-error>
    </mat-form-field>

    <div style="text-align:center; color:red;margin-left: auto;margin-right: auto;">
         <mat-label *ngIf="bladLogowania" style="">Błędny login lub hasło ... </mat-label>
    </div>
    <div mat-dialog-actions style="margin-bottom: 10px;margin-left: auto;margin-right: auto;">
      <button type="submit" mat-stroked-button style="display: block;" color="primary" mat-button class="btn btn-outline-dark btn-primary" >
        <mat-icon>perm_identity</mat-icon>
        Logowanie</button>

      <button mat-stroked-button style="margin-left: auto; display: block;" color="warn" mat-button type="button" class="btn btn-outline-dark" (click)="zamknijModal()">Anuluj
          <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </form>

</div>
