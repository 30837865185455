import { AlarmRodzajuOplaty_class } from './../../modele/AlarmRodzajuOplaty_class';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { RodzajOplatyAlarmFormComponent } from 'src/app/form/rodzaj-oplaty-alarm-form/rodzaj-oplaty-alarm-form.component';

@Component({
  selector: 'app-lista-alarmow-rodzaju-oplat',
  templateUrl: './lista-alarmow-rodzaju-oplat.component.html',
  styleUrls: ['./lista-alarmow-rodzaju-oplat.component.css']
})
export class ListaAlarmowRodzajuOplatComponent implements OnInit {

  constructor(private dialogRefAll: MatDialog,
    private ApiService:ApiServiceService,
    private dialogRef: MatDialogRef<ListaAlarmowRodzajuOplatComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService) { }


  rodzajOplatyOpis="";
  alarmOplatKolumny: string[] = ["nazwaAlarmu","alarmDzienMiesiaca","alarmOdMiesiaca","alarmCoMiesiecy","aktywny"];
  idRodzajOplaty=-1;
  alarmyOplat: AlarmRodzajuOplaty_class[];
  dataSourceAlarmyOplat = new MatTableDataSource<AlarmRodzajuOplaty_class>();
  odswiezListeAlarmow=false;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    if(this.data.idRodzajOplaty!=null)
    {
      this.idRodzajOplaty = this.data.idRodzajOplaty;
      this.rodzajOplatyOpis = this.data.rodzajOplatyOpis;
      this.pobierzAlarmy();
    }

    this.dataSourceAlarmyOplat.paginator=this.paginator;
    this.dataSourceAlarmyOplat.sort=this.sort;
  }

  pobierzAlarmy()
  {
    this.spinner.show();

    this.ApiService.odczytajListeAlarmowRodzajuOplatyUzytkownika(this.idRodzajOplaty)
    .subscribe((listaAlarmow:AlarmRodzajuOplaty_class[])=>
    {
      this.alarmyOplat=listaAlarmow;
      this.dataSourceAlarmyOplat.data = this.alarmyOplat;
      this.spinner.hide();
    }
    ,
    err =>
    {
      this.spinner.hide();
      console.error("blad " + err);

      if(err.status==401)
      {
        localStorage.clear();
        this.dialogRefAll.closeAll();
      }
    }
    );


  }

  stopPropag(event)
  {
    event.stopPropagation();
  }

  zmienAktywny(value, idRodzajOplatyAlarm)
  {

    console.log("zmien aktywny value"+value.checked+" id "+idRodzajOplatyAlarm)
    let phpUrl = `${ApiServiceService.PHP_API_SERVER}zmienAlarmRodzajuOplatyAktywny.php`;
    let aktywna =0;
    if (value.checked) aktywna=1;
      this.http
          .post(phpUrl, {
            idRodzajOplatyAlarm: idRodzajOplatyAlarm,
               aktywna: aktywna,
              token: localStorage.getItem("token")
          })

          .subscribe((res: Response) => {
            console.error(res)
            if(res!=null)
             {
               if(Number(res) == -401)
               {
                localStorage.clear();
                this.dialogRefAll.closeAll();
               }
               else
               {
                    this.odswiezListeAlarmow=true;
                  let komunikat="Alarm opłaty został aktywowany.";
                  if (!value.checked) komunikat="Alarm opłaty został deaktywowany.";
                    this._snackBar.open(komunikat, "", {
                    duration: 2000,
                     });
              }
            }
          },
          error => console.error(error)
        );
  }

  otworzModalAlarmu(idAlarm_parm)
  {
    let dialogRef = this.dialog.open(RodzajOplatyAlarmFormComponent, {
      data: {idAlarm: idAlarm_parm,
        idRodzajOplaty: this.idRodzajOplaty,
        rodzajOplatyOpis : this.rodzajOplatyOpis},
      width: '400px',
      height:'auto'
    });


    dialogRef.afterClosed().subscribe(result => {

      if(result) {
        this.odswiezListeAlarmow=true;
        this.pobierzAlarmy();
       }
    });
  }

  zamknj(odswiezDane)
  {
    this.dialogRef.close(odswiezDane);
  }

}
