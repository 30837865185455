import { UmowaNabywcy } from './../../modele/UmowaNabywcy';
import { ApiServiceService } from './../../api-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient, HttpParams } from "@angular/common/http";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-umowa-nabywcy-form',
  templateUrl: './umowa-nabywcy-form.component.html',
  styleUrls: ['./umowa-nabywcy-form.component.css']
})
export class UmowaNabywcyFormComponent implements OnInit {

  public umowaForm:FormGroup;
  public submitted:boolean = true;
  public id_nabywcy:Number=-1;
  public id_umowy:Number=-1;

  constructor(private dialogRefAll: MatDialog,
    public dialogRef: MatDialogRef<UmowaNabywcyFormComponent>,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private apiService : ApiServiceService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data.idNabywcy);
    if(this.data.idNabywcy!=null) this.id_nabywcy= this.data.idNabywcy;

    this.umowaForm = new FormGroup(
      {
        nrUmowy: new FormControl('',Validators.required),
        opis:new FormControl(''),
        nrZamowienia:new FormControl(''),
        kontoKG:new FormControl(''),
        warunekTermPlastnosci: new FormControl('0'),
        formaPlatnosci: new FormControl('0')
      }
    )

    console.log("Umowa nabywcy id modal "+this.data.idUmowa);

    if(this.data.idUmowa!=null && this.data.idUmowa!=-1)
    {
      this.id_umowy = this.data.idUmowa;
      this.pobierzDaneUmowy(this.id_umowy);
    }
  }

  public bladWalidacji = (controlName: string, errorName: string) =>{
    return this.umowaForm.controls[controlName].hasError(errorName);
  }

  pobierzDaneUmowy(idUmowy:Number)
  {
    console.log("pobieranie danych umowy id "+idUmowy);

      this.apiService.daneUmowyPoId(idUmowy)
      .subscribe((daneUmowy:UmowaNabywcy[])=>

      {
          this.umowaForm.controls["nrUmowy"].setValue(daneUmowy[0].nrUmowy);
          this.umowaForm.controls["opis"].setValue(daneUmowy[0].opis);
          this.umowaForm.controls["nrZamowienia"].setValue(daneUmowy[0].nrZamowienia);
          this.umowaForm.controls["kontoKG"].setValue(daneUmowy[0].kontoKG);
          this.umowaForm.controls["formaPlatnosci"].setValue(daneUmowy[0].idFormaPlatnosci);
          this.umowaForm.controls["warunekTermPlastnosci"].setValue(daneUmowy[0].idWarunekPlatnosci);
      },
      err  => {
        this.spinner.hide();
        console.log("blad umowa" + err);
        if(err.status==401)
        {
          console.log("zamykanie wszystkich modali");
          localStorage.clear();

          this.dialogRefAll.closeAll();
        }

      });


  }

  onSubmit()
  {
    console.log("onsubmit");
    this.submitted = true;

      // stop here if form is invalid
    if (this.umowaForm.invalid) {
        console.log("odrzut");
        return this.umowaForm.invalid;
      }


    console.log("walidacja ok.");
    this.zapiszUmowe();

  }

  zapiszUmowe() {
    console.log("zapisz nabywace");
    let phpUrl = `${ApiServiceService.PHP_API_SERVER}dodajUmoweNabywcy.php`;
    if(this.id_umowy!=-1) phpUrl= `${ApiServiceService.PHP_API_SERVER}zmienUmoweNabywcy.php`;

    console.log(this.umowaForm.value.warunekTermPlastnosci)

    this.http
        .post(phpUrl, {
          token: localStorage.getItem("token"),
          id_nabywcy: this.id_nabywcy,
          nrUmowy: this.umowaForm.value.nrUmowy,
          opis: this.umowaForm.value.opis,
          nrZamowienia: this.umowaForm.value.nrZamowienia,
          kontoKG: this.umowaForm.value.kontoKG,
          idFormaPlatnosci: this.umowaForm.value.formaPlatnosci,
          warunekTermPlastnosci: this.umowaForm.value.warunekTermPlastnosci,
          komentarz: '',
          id_umowy: this.id_umowy,
        })

        .subscribe((res: Response) => {
          console.error(res)

          let idUmowy_zapisanej = Number(res);


          if(idUmowy_zapisanej == -401)
            {
              localStorage.clear();
              this.dialogRefAll.closeAll();
            }
            else
            {
              let komunikat : string = "Umowa została dodana.";
              if(this.id_umowy != -1) komunikat = "Zmiany w umowie zostały zapisane."
              this._snackBar.open(komunikat, "", {
                duration: 2000,
              });
              this.dialogRef.close(true);
          }
        },
        error => console.error(error)
     );
  }

  dlWarunku()
  {

    return this.umowaForm.value.warunekTermPlastnosci != '' && this.umowaForm.value.warunekTermPlastnosci>=0;
  }

  zamknj()
  {
    this.dialogRef.close(false);
  }

}
