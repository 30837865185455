<ngx-spinner style="height: 100%;width: 100%;"
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="display:flex;">
  <h4 class="modal-title" >Lista rodzajów opłat</h4>
  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>
    <br>
  </div>

<button mat-stroked-button (click)="otworzModalDodajRodzaj(-1)" >Dodaj rodzaj opłaty</button>
<table mat-table [dataSource]="dataSourceRodzajeOplat" class="rodzajeOplatLista mat-elevation-z8" multiTemplateDataRows style="width: 100%;" matSortActive="dataOplacenia" matSortDirection="desc" matSort>

  <ng-container matColumnDef="rodzajOplaty" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rodzaj </th>
    <td mat-cell *matCellDef="let element" > {{element.rodzajOplaty}} </td>
  </ng-container>

  <ng-container matColumnDef="aktywny" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>  Aktywna </th>
    <td mat-cell *matCellDef="let element"> <mat-slide-toggle [checked]="element.aktywny==1" (click)="clickStopPropagation($event)" (change)="zmienAktywny($event, element.idRodzajOplaty)"></mat-slide-toggle> </td>
  </ng-container>

  <ng-container matColumnDef="edytuj" style="width: 25%;">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
    <td mat-cell *matCellDef="let element"><button mat-button (click)="otworzListeAlarmow($event, element.idRodzajOplaty,element.rodzajOplaty)">Alarmy</button>  </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="rodzajeOplatKolumny"></tr>
  <tr mat-row *matRowDef="let row; columns: rodzajeOplatKolumny;" (click)="otworzModalDodajRodzaj(row.idRodzajOplaty)"></tr>
  </table>
  <mat-paginator  class="pagOplaty" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
