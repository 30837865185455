import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dane-uzytkownika',
  templateUrl: './dane-uzytkownika.component.html',
  styleUrls: ['./dane-uzytkownika.component.css']
})
export class DaneUzytkownikaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getLocalStorageKey(key)
  {
      return localStorage.getItem(key);
  }

}
