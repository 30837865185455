<ngx-spinner
>
<p style="font-size: 20px; color: white">Ładowanie...</p>
</ngx-spinner>
<div style="display:flex;">
<h2 class="modal-title">Edycja danych firmy</h2>

  <button mat-mini-fab   style="margin-left: auto; display: block;" color="silver" (click)="zamknj()" aria-label="Example icon button with a plus one icon">
      <mat-icon>close</mat-icon>
    </button>


</div>
<br>
<div class="mat-dialog-content" style="height: auto;">

<form  [formGroup]="daneUzytkowniaForm" (ngSubmit)="onSubmit()"  autocomplete="off">

  <mat-form-field style="width: 100%;" appearance="legacy">
    <mat-label>Nazwa</mat-label>
    <input matInput formControlName ="nazwaFirmy" maxlength="40" aria-multiline="true">
    <mat-error *ngIf="hasError('nazwaFirmy', 'required')">Nazwa jest wymagana</mat-error>
  </mat-form-field>

<div class="center">
  <mat-form-field  appearance="legacy">
    <mat-label>NIP</mat-label>
    <input matInput formControlName="nip" placeholder="XXX-XXX-XX-XX"  mask='000-000-00-00' [validation]="true">
    <mat-error *ngIf="hasError('nip', 'required')">NIP jest wymagany</mat-error>
    <mat-error *ngIf="hasError('nip', 'mask')">Nieprawidłowy format</mat-error>
  </mat-form-field>
</div>


<div class="center">
  <mat-form-field style="width: auto" appearance="legacy">
    <mat-label>miasto</mat-label>
    <input matInput formControlName="miasto" maxlength="20">
    <mat-error *ngIf="hasError('miasto', 'required')">Miasto jest wymagane.</mat-error>
  </mat-form-field>
</div>

  <div class="center">
    <mat-form-field style="width: auto;" appearance="legacy">
    <mat-label>Ulica</mat-label>
    <input matInput formControlName="ulica" maxlength="20">
    <mat-error *ngIf="hasError('ulica', 'required')">Ulica jest wymagana.</mat-error>
    </mat-form-field>
  </div>

<div class="center">
  <mat-form-field style="width: auto" appearance="legacy">
    <mat-label>Nr mieszkania</mat-label>
    <input matInput formControlName="nrMieszkania" maxlength="6">
  </mat-form-field>
</div>
<div class="center">
  <mat-form-field  >
    <mat-label>Kod pocztowy</mat-label>
    <input matInput formControlName="kodPocztowy" placeholder="XX-XXX"  mask='00-000' [validation]="true">
    <mat-error *ngIf="hasError('kodPocztowy', 'required')">Kod pocztowy jest wymagany</mat-error>
    <mat-error *ngIf="hasError('kodPocztowy', 'mask')">Niepoprawny format.</mat-error>
  </mat-form-field>
</div>

  <mat-form-field appearance="legacy" style="width: 100%;">
    <mat-label>Nr konta bankowego</mat-label>
    <input matInput formControlName="nrKontaBankowego" placeholder="XX-XXX"  mask='00 0000 0000 0000 0000 0000 0000' [validation]="true">
    <mat-error *ngIf="hasError('nrKontaBankowego', 'required')">Nr konta jest wymagany</mat-error>
    <mat-error *ngIf="hasError('nrKontaBankowego', 'mask')">Niepoprawny format.</mat-error>
  </mat-form-field>

  <div mat-dialog-actions style="margin-bottom: 1px">
    <button type="submit" mat-stroked-button style="margin-left: auto;margin-right: 2em; display: block;" color="primary" mat-button class="btn btn-outline-dark btn-primary" >
      <mat-icon>save</mat-icon>
      Zapisz</button>

    <button mat-stroked-button style="margin-right: auto; display: block;" color="warn" mat-button type="button" class="btn btn-outline-dark" (click)="zamknj()">Anuluj
      <mat-icon>cancel</mat-icon>
    </button>

  </div>

</form>

</div>

